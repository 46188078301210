import { SHOW_SNACKBAR, FeedbackContextInterface } from '../FeedbackInterface';

export function showSnackbarAction(
  state: FeedbackContextInterface,
  action: SHOW_SNACKBAR
): FeedbackContextInterface {
  return {
    ...state,
    snackbar: {
      message: action.message,
      variant: action.variant,
      timestamp: new Date().getTime()
    }
  };
}
