import { convertCurrencyToNumber } from 'src/utils/localePriceHelper';
import {
  ProposalsContextInterface,
  ProposalsTypesEnum
} from '../ProposalsInterface';

export type UPDATE_AMOUNT = {
  type: ProposalsTypesEnum.UPDATE_AMOUNT;
  amount: number | string;
};

function isAmountValid(amount: number) {
  if (amount == null) return false;

  return amount >= 1000 && amount <= 3000000;
}

export function updateAmountAction(
  state: ProposalsContextInterface,
  action: UPDATE_AMOUNT
): ProposalsContextInterface {
  const newAmount: number =
    typeof action.amount == 'number'
      ? action.amount
      : convertCurrencyToNumber(action.amount);

  const roundedAmount = Math.round(newAmount);

  return {
    ...state,
    createProposal: {
      ...state.createProposal,
      amount: roundedAmount,
      isAmountValid: isAmountValid(roundedAmount)
    }
  };
}
