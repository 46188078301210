import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

import objFromArray from 'src/utils/objFromArray';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
  isOpenSidebarConversation: true,
  isOpenSidebarInfo: true
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = objFromArray(contacts);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
      // @ts-ignore ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    // @ts-ignore ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
    getConversationsSuccess(state, action) {
      const conversations = action.payload;

      state.conversations.byId = objFromArray(conversations);

      // @ts-ignore ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },
    // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message

    // GET CONVERSATION
    // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        // @ts-ignore
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (!state.conversations.allIds.includes(conversation.id)) {
          // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          state.conversations.allIds.push(conversation.id);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const {
        conversationId,
        messageId,
        message,
        contentType,
        attachments,
        createdAt,
        senderId
      } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType: contentType,
        attachments: attachments,
        createdAt: createdAt,
        senderId: senderId
      };
      // @ts-ignore
      state.conversations.byId[conversationId].messages.push(newMessage);
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;

      // @ts-ignore
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipient(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },

    // SIDEBAR
    onOpenSidebarConversation(state) {
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
      state.isOpenSidebarConversation = true;
    },

    onCloseSidebarConversation(state) {
      state.isOpenSidebarConversation = false;
    },

    onOpenSidebarInfo(state) {
      state.isOpenSidebarInfo = true;
    },

    onCloseSidebarInfo(state) {
      state.isOpenSidebarInfo = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addRecipient,
  onSendMessage,
  onOpenSidebarInfo,
  // @ts-ignore ts-migrate(7006) FIXME: Parameter 'conversationKey' implicitly has an 'any... Remove this comment to see the full error message
  onCloseSidebarInfo,
  // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  resetActiveConversation,
  onOpenSidebarConversation,
  onCloseSidebarConversation
} = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/contacts');
      dispatch(slice.actions.getContactsSuccess(response.data.contacts));
    } catch (error) {
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'conversationId' implicitly has an 'any'... Remove this comment to see the full error message
      dispatch(slice.actions.hasError(error));
    }
  };
}

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
// ----------------------------------------------------------------------

export function getConversations() {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/conversations');
      dispatch(
        slice.actions.getConversationsSuccess(response.data.conversations)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'conversationKey' implicitly has an 'any... Remove this comment to see the full error message
// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/conversation', {
        params: { conversationKey }
      });
      dispatch(
        slice.actions.getConversationSuccess(response.data.conversation)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId: any) {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('/api/chat/conversation/mark-as-seen', {
        params: { conversationId }
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey: any) {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/participants', {
        params: { conversationKey }
      });
      dispatch(
        slice.actions.getParticipantsSuccess(response.data.participants)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
