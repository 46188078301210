import React, { useContext } from 'react';
import { CustomersContext } from './CustomersContext/CustomersContext';
import { CustomersTypesEnum } from './CustomersContext/CustomersInterface';
import CustomersTable from './form/CustomersTable';
import { HeaderDashboard } from 'src/layouts/Common';
import { PATH_APP } from 'src/routes/paths';
import EmptyPlaceholder from '../../components/EmptyPlaceholder';
import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

interface CustomersPageInterface {}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const CustomersPage: React.FC<CustomersPageInterface> = () => {
  const classes = useStyles();
  const { stateCustomers, dispatchCustomers } = useContext(CustomersContext);

  return (
    <>
      <Page title="Clientes | Ockbank" className={classes.root}>
        <Container>
          <HeaderDashboard
            heading="Clientes"
            links={[
              { name: 'Dashboard', href: PATH_APP.root },
              // @ts-ignore ts-migrate(7006) FIXME: Parameter 'arg' implicitly has an 'any' type.
              { name: 'Clientes' }
            ]}
          />
          {stateCustomers.customers.length === 0 ? (
            <EmptyPlaceholder
              title="Oops, nenhum cliente aqui!"
              subtitle='Para criar seu primeiro cliente, clique no botão "Novo"'
              buttonTitle="Novo"
              path={undefined}
              onClick={() => {
                dispatchCustomers({
                  type: CustomersTypesEnum.OPEN_CUSTOMERS_FORM,
                  form: { open: true }
                });
              }}
            />
          ) : (
            <CustomersTable customers={stateCustomers.customers} />
          )}
        </Container>
      </Page>
    </>
  );
};

export default CustomersPage;
