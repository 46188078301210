import { CustomerInterface } from './../../../customers/interfaces/CustomerInterface';
import {
  ProposalsContextInterface,
  ProposalsTypesEnum
} from '../ProposalsInterface';

export type UPDATE_SELECTED_COSTUMER = {
  type: ProposalsTypesEnum.UPDATE_SELECTED_COSTUMER;
  costumer: CustomerInterface | undefined | null;
};

export function updateSelectedCostumerAction(
  state: ProposalsContextInterface,
  action: UPDATE_SELECTED_COSTUMER
): ProposalsContextInterface {
  return {
    ...state,
    createProposal: {
      ...state.createProposal,
      selectedCostumer: action.costumer
    }
  };
}
