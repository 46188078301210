// import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
// import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
// import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';

import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import {
  EditableCell,
  ExtendableCellInterface
} from '../../../../components/MUITSDatatable';
import { HTMLElementInputTypeEnum } from '../../../../components/MUITSDatatable/MUITSDatatableInterface';
import {
  GenericControllerTextfieldInterface,
  TextfieldController
} from '../../../../components/TextfieldController';
import { CustomersContext } from '../../CustomersContext/CustomersContext';
import { CustomersTypesEnum } from '../../CustomersContext/CustomersInterface';
import { updateCustomer } from '../../services/updateCustomer';
import { VatNumberCpfCnpjEnum } from './CpfCnpjController';

interface CompanyNameInterface extends ExtendableCellInterface {}

export const CompanyNameTextfield: React.FC<
  GenericControllerTextfieldInterface<CompanyNameInterface>
> = ({ control, errors }) => {
  const isPJ = control.getValues('cpfOrCnpj') === VatNumberCpfCnpjEnum.CNPJ;

  return isPJ ? (
    <TextfieldController
      control={control}
      errors={errors}
      name="company_name"
      placeholder="Razão social"
      rules={{ required: false }}
      textfieldProps={{ label: 'Razão social' }}
    />
  ) : null;
};

const CompanyName: React.FC<CompanyNameInterface> = ({
  tableMeta,
  updateValue,
  value
}): JSX.Element => {
  const { dispatchCustomers } = useContext(CustomersContext);
  const [cookies] = useCookies(['authToken']);
  const id = tableMeta.rowData[0];

  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    const toUpdate = { company_name: valueToUpdate };

    updateCustomer(id, toUpdate, cookies.authToken);

    dispatchCustomers({
      type: CustomersTypesEnum.UPDATE_CUSTOMER_FIELD,
      uid: id,
      customer: toUpdate
    });
  };

  return (
    <EditableCell
      type={HTMLElementInputTypeEnum.Text}
      value={value}
      tableMeta={tableMeta}
      updateValue={updateValue}
      handleUpdate={handleUpdate}
      rules={{
        required: 'Campo obrigatório'
      }}
    />
  );
};

export default CompanyName;
