import {
  UsersContextInterface,
  UsersFormInterface,
  UsersFormTypeEnum,
  UsersTypesEnum
} from '../UsersInterface';

export type OPEN_CUSTOMERS_FORM = {
  type: UsersTypesEnum.OPEN_CUSTOMERS_FORM;
  form: Omit<UsersFormInterface, 'type'>;
};

export function openUsersFormAction(
  state: UsersContextInterface,
  action: OPEN_CUSTOMERS_FORM
): UsersContextInterface {
  return {
    ...state,
    form: {
      ...action.form,
      type:
        action.form.editUser === undefined
          ? UsersFormTypeEnum.Create
          : UsersFormTypeEnum.Edit
    }
  };
}
