import React, { createContext, useEffect, useReducer } from 'react';
import Reducer from './UsersReducer';
import { usersInitialState } from './UsersInitializer';
import {
  UsersContextInterface,
  UsersAction,
  UsersTypesEnum
} from './UsersInterface';
import useSWR from 'swr';
import { listUsers } from '../services/listUsers';
import { useCookies } from 'react-cookie';

export type UsersDispatchType = React.Dispatch<UsersAction>;

interface UsersOckUserInterface {
  stateUsers: UsersContextInterface;
  dispatchUsers: UsersDispatchType;
}

export const UsersContext = createContext<UsersOckUserInterface>({
  stateUsers: usersInitialState,
  dispatchUsers: () => console.warn('UsersDispatch not ready')
});

const UsersUser: React.FC = ({ children }) => {
  const [cookies] = useCookies(['authToken']);
  const [stateUsers, dispatchUsers] = useReducer(Reducer, usersInitialState);

  const usersRequest = () => listUsers(cookies.authToken);
  const { data } = useSWR('/users', usersRequest, {});

  useEffect(() => {
    const users = data?.data;
    users &&
      dispatchUsers({
        type: UsersTypesEnum.UPDATE_CUSTOMERS_LIST,
        users
      });
  }, [data, dispatchUsers]);

  const globals = {
    stateUsers,
    dispatchUsers
  };

  return (
    <UsersContext.Provider value={globals}>{children}</UsersContext.Provider>
  );
};

export default UsersUser;
