import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

// xx.xxx.xxx/xxxx-xx
const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

const CNPJType = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={cnpjMask}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
};

CNPJType.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default CNPJType;
