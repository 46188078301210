// import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
// import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
// import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';

import React from 'react';
import { useCookies } from 'react-cookie';
import {
  EditableCell,
  ExtendableCellInterface
} from '../../../../components/MUITSDatatable';
import { HTMLElementInputTypeEnum } from '../../../../components/MUITSDatatable/MUITSDatatableInterface';
import {
  GenericControllerTextfieldInterface,
  TextfieldController
} from '../../../../components/TextfieldController';
import { updateCustomer } from '../../services/updateCustomer';
import { VatNumberCpfCnpjEnum } from './CpfCnpjController';
import CNPJType from './masks/CNPJ';
import CPFType from './masks/CPF';
import { validCNPJ, validCPF } from './validators/Validators';

interface VatNumberInterface extends ExtendableCellInterface {}

export const VatNumberTextfield: React.FC<
  GenericControllerTextfieldInterface<VatNumberInterface>
> = ({ control, errors, defaultValue }) => {
  const isPJ = control.getValues('cpfOrCnpj') === VatNumberCpfCnpjEnum.CNPJ;
  const value = control.getValues('cpfOrCnpj');

  return (
    <>
      <TextfieldController
        control={control}
        errors={errors}
        name="vat_number"
        placeholder={value?.toUpperCase()}
        defaultValue={defaultValue}
        rules={{
          required: 'Este campo é obrigatório',
          validate: {
            cpfValidator: (str: string) =>
              !isPJ ? (validCPF(str) ? true : 'CPF inválido') : true,
            cnpjValidator: (str: string) =>
              isPJ ? (validCNPJ(str) ? true : 'CNPJ inválido') : true
          }
        }}
        textfieldProps={{ label: value?.toUpperCase() }}
        // @ts-ignore
        InputProps={{
          inputComponent:
            value === VatNumberCpfCnpjEnum.CNPJ ? CNPJType : CPFType
        }}
      />
    </>
  );
};

const VatNumber: React.FC<VatNumberInterface> = ({
  tableMeta,
  updateValue,
  value
}): JSX.Element => {
  const [cookies] = useCookies(['authToken']);
  const id = tableMeta.rowData[0];

  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    updateCustomer(id, { company_name: valueToUpdate }, cookies.authToken);
  };

  return (
    <EditableCell
      type={HTMLElementInputTypeEnum.Text}
      value={value}
      tableMeta={tableMeta}
      disabled
      updateValue={updateValue}
      handleUpdate={handleUpdate}
      rules={{
        required: 'Campo obrigatório'
      }}
    />
  );
};

export default VatNumber;
