import { useSnackbar } from 'notistack';
import { login } from './../views/auth/services/login';
import { OckUserInterface } from 'src/views/auth/interfaces/UserInterface';
import firebase from 'firebase/app';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/AuthContext/AuthContext';
import { AuthTypesEnum } from 'src/contexts/AuthContext/AuthInterface';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { VariantEnum } from 'src/services/axiosService';
import { updateUser } from 'src/views/user/services/updateUser';

interface LoginMethodInterface {
  email: string;
  password: string;
}

export enum UserRolesEnum {
  Admin = 'admin',
  User = 'user'
}

interface ExtendedUserInterface extends Partial<OckUserInterface> {
  displayName: string;
  email: string;
  photoURL: string;
  phoneNumber: string;
  country: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
  about: string;
  role: UserRolesEnum | string;
  isPublic: boolean;
}

interface UseAuthInterface {
  method: string;
  user: ExtendedUserInterface | undefined;
  isLoading: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  login: (props: LoginMethodInterface) => Promise<void>;
  loginWithGoogle: () => Promise<firebase.auth.UserCredential> | undefined;
  loginWithFaceBook: () => Promise<firebase.auth.UserCredential> | undefined;
  loginWithTwitter: () => Promise<firebase.auth.UserCredential> | undefined;
  loginWithMicrosoft: () => Promise<firebase.auth.UserCredential> | undefined;
  register: () => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void> | undefined;
  updateProfile: () => Promise<void> | undefined;
}

export default function useAuth(
  method: 'jwt' | 'firebase' = 'firebase'
): UseAuthInterface {
  const [cookies, setCookie] = useCookies(['authToken']);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { firebaseAuth, dispatchAuth, stateAuth } = useContext(AuthContext);

  const providerGoogle = new firebase.auth.GoogleAuthProvider();
  const providerTwitter = new firebase.auth.GoogleAuthProvider();
  const providerFacebook = new firebase.auth.GoogleAuthProvider();
  const providerMicrosoft = new firebase.auth.OAuthProvider('microsoft.com');

  const isAuthenticated =
    stateAuth.loggedUser !== undefined && cookies.authToken;

  return {
    method: 'firebase',
    user: {
      displayName: 'displayName',
      email: 'email',
      photoURL: 'photoURL',
      phoneNumber: 'phoneNumber',
      country: 'country',
      address: 'address',
      state: 'state',
      city: 'city',
      zipCode: 'zipCode',
      about: 'about',
      role: UserRolesEnum.Admin,
      isPublic: false,
      ...stateAuth.loggedUser
    },
    isLoading: false,
    isAuthenticated,
    isAdmin: stateAuth.loggedUser?.role === UserRolesEnum.Admin,
    login: async (props: LoginMethodInterface) => {
      try {
        const firebaseUser = await firebaseAuth?.signInWithEmailAndPassword(
          props.email,
          props.password
        );

        const tokenFirebase = await firebaseUser?.user?.getIdToken();
        console.debug(tokenFirebase);

        if (!tokenFirebase) {
          enqueueSnackbar('Não foi possível fazer login', { variant: 'error' });
          return;
        }

        const { data, message, variant } = await login(tokenFirebase);
        dispatchAuth({
          type: AuthTypesEnum.AUTHENTICATE_USER,
          loggedUser: data.user,
          token: data.token,
          navigate: history,
          setCookie
        });
        enqueueSnackbar(message, { variant });
      } catch (error) {
        let errorMessage = '';
        switch (error.code) {
          case 'auth/internal-error':
            errorMessage = 'Ocorreu um problema, contate a Ockbank.';
            break;

          case 'auth/invalid-credential':
            errorMessage = 'Email ou senha incorretos, tente novamente.';
            break;

          case 'auth/invalid-email	':
            errorMessage = 'O email informado é incorreto, tente novamente';
            break;

          case 'auth/invalid-email-verified	':
            errorMessage = 'O email informado é inválido.';
            break;

          case 'auth/user-not-found':
            errorMessage =
              'O usuário não foi encontrado, entre em contato com a Ockbank';
            break;

          default:
            errorMessage =
              'Não foi possível fazer login, entre em contato com a Ockbank';
            break;
        }

        enqueueSnackbar(errorMessage, { variant: VariantEnum.Error });
      }
    },
    loginWithGoogle: () => firebaseAuth?.signInWithPopup(providerGoogle),
    loginWithFaceBook: () => firebaseAuth?.signInWithPopup(providerFacebook),
    loginWithTwitter: () => firebaseAuth?.signInWithPopup(providerTwitter),
    loginWithMicrosoft: () => firebaseAuth?.signInWithPopup(providerMicrosoft),
    logout: async () =>
      await dispatchAuth({
        type: AuthTypesEnum.LOGOUT_USER
      }),
    resetPassword: (email: string) =>
      firebaseAuth?.sendPasswordResetEmail(email),
    // @ts-ignore
    register: () => {},
    // @ts-ignore
    updateProfile: async (formData: any) => {
      if (!stateAuth.loggedUser?.uid) return;

      const response = await updateUser(
        stateAuth.loggedUser?.uid,
        formData,
        cookies.authToken
      );

      enqueueSnackbar(response.message, { variant: response.variant });
    }
  };
}
