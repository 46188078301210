import React from 'react';

import useAuth from 'src/hooks/useAuth';

import { Redirect } from 'react-router-dom';

import { PATH_APP } from 'src/routes/paths';

const AdminProtect: React.FC = ({ children }) => {
  const { isAdmin } = useAuth();

  if (!isAdmin) {
    return <Redirect to={PATH_APP.root} />;
  }

  return <h1>{children}</h1>;
};

export default AdminProtect;
