import { closeDialogAction, CLOSE_DIALOG } from './actions/closeDialogAction';
import { openDialogAction, OPEN_DIALOG } from './actions/openDialogAction';
import { LayoutContextInterface, LayoutTypesEnum } from './LayoutInterface';
import {
  configurePageAction,
  CONFIGURE_PAGE
} from './actions/configurePageAction';

export type LayoutAction = CONFIGURE_PAGE | OPEN_DIALOG | CLOSE_DIALOG;

function layoutReducer(
  state: LayoutContextInterface,
  action: LayoutAction
): LayoutContextInterface {
  switch (action.type) {
    case LayoutTypesEnum.CONFIGURE_PAGE:
      return configurePageAction(state, action);

    case LayoutTypesEnum.OPEN_DIALOG:
      return openDialogAction(state, action);

    case LayoutTypesEnum.CLOSE_DIALOG:
      return closeDialogAction(state, action);

    default:
      return state;
  }
}

export default layoutReducer;
