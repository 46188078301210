/* eslint-disable @typescript-eslint/no-use-before-define */
import { MUIDataTableMeta } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, RegisterOptions } from 'react-hook-form';
import {
  InputBase,
  Box,
  FormHelperText,
  InputBaseProps,
  LinearProgress
} from '@material-ui/core';
import { HTMLElementInputTypeEnum } from './MUITSDatatableInterface';
import styled from 'styled-components';

export interface ExtendableCellInterface
  extends Omit<EditableCellInterface, 'handleUpdate'> {}

export interface EditableCellInterface extends InputBaseProps {
  value: unknown;
  tableMeta: MUIDataTableMeta;
  updateValue: (value: string) => void;
  handleUpdate: (valueToUpdate: string) => Promise<void>;
  type?: HTMLElementInputTypeEnum;
  rules?: RegisterOptions;
}
const CustomInputBase = styled(InputBase)`
  input {
    padding: 6px;
    &:focus,
    &:hover {
      padding: 4px;
      border: 2px solid #3f4c67;
      border-radius: 4px;
    }
  }
`;

const KEY_PRESS_TIMEOUT = 1000;

const EditableCell: React.FC<EditableCellInterface> = ({
  value,
  type,
  updateValue,
  tableMeta,
  handleUpdate,
  disabled,
  rules,
  ...props
}) => {
  const [ruleTimeout, setRuleTimeout] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [keypressDelay, setKeypressDelay] = useState<any | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputName = tableMeta.columnData.name;
  const inputDefault = value || tableMeta.rowData[tableMeta.columnIndex];
  const {
    control,
    handleSubmit,
    errors,
    formState,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  useEffect(() => {
    setValue(inputName, inputDefault);
  }, [inputDefault, inputName, setValue]);

  useEffect(() => {
    if (ruleTimeout && formState.isValid) {
      const valueToUpdate = getValues(inputName);

      setIsLoading(true);
      updateValue(valueToUpdate);
      handleUpdate(valueToUpdate)
        // .then(() => updateValue(valueToUpdate))
        .catch(() =>
          alert('Ocorreu um erro ao tentar editar, tente novamente.')
        )
        .finally(() => setTimeout(() => setIsLoading(false), 1000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isValid, ruleTimeout]);

  const startTimeout = (): void => {
    if (formState.isValid) {
      setRuleTimeout(false);
      clearTimeout(keypressDelay);
      const timeout = setTimeout(() => setRuleTimeout(true), KEY_PRESS_TIMEOUT);
      setKeypressDelay(timeout);
    }
  };

  const onSubmit = () => {};

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column">
        <Controller
          render={({ onChange, onBlur, value }) => {
            return (
              <CustomInputBase
                type={type}
                disabled={isLoading}
                onKeyUp={() =>
                  type === HTMLElementInputTypeEnum.Text && startTimeout()
                }
                onClick={() =>
                  type !== HTMLElementInputTypeEnum.Text && startTimeout()
                }
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                {...props}
              />
            );
          }}
          rules={rules}
          name={inputName}
          control={control}
          defaultValue={inputDefault}
        />

        {/* <pre>{JSON.stringify(formState, undefined, 2)}</pre> */}

        {isLoading && <LinearProgress />}

        {!formState.isValid && errors[inputName]?.message && (
          <FormHelperText error={errors[inputName]}>
            {errors[inputName].message}
          </FormHelperText>
        )}
      </Box>
    </form>
  );
};

export default React.memo(EditableCell);
