import { CustomerInterface } from '../../interfaces/CustomerInterface';
import {
  CustomersContextInterface,
  CustomersTypesEnum
} from '../CustomersInterface';

export type UPDATE_SINGLE_CUSTOMER = {
  type: CustomersTypesEnum.UPDATE_SINGLE_CUSTOMER;
  customer: CustomerInterface;
};

export function updateSingleCustomerAction(
  state: CustomersContextInterface,
  action: UPDATE_SINGLE_CUSTOMER
): CustomersContextInterface {
  const newCustomers = state.customers.map((customer) =>
    action.customer.uid === customer.uid ? action.customer : customer
  );

  return {
    ...state,
    customers: newCustomers
  };
}
