import { LayoutContextInterface, LayoutTypesEnum } from '../LayoutInterface';

export type CONFIGURE_PAGE = {
  type: LayoutTypesEnum.CONFIGURE_PAGE;
  page: Partial<LayoutContextInterface>;
};

export function configurePageAction(
  state: LayoutContextInterface,
  action: CONFIGURE_PAGE
): LayoutContextInterface {
  return {
    ...state,
    ...action.page
  };
}
