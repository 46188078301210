import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPosts() {
  // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts/all');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
export function getPostsInitial(index, step) {
  // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts', {
        params: { index, step }
      });
      const results = response.data.results.length;
      const maxLength = response.data.maxLength;

      dispatch(slice.actions.getPostsInitial(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'title' implicitly has an 'any' type.
export function getPost(title) {
  // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/post', {
        params: { title }
      });
      dispatch(slice.actions.getPostSuccess(response.data.post));
    } catch (error) {
      console.error(error);
      // @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      dispatch(slice.actions.hasError());
    }
  };
}

// @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'title' implicitly has an 'any' type.
// ----------------------------------------------------------------------

export function getRecentPosts(title) {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/blog/posts/recent', {
        params: { title }
      });

      dispatch(slice.actions.getRecentPostsSuccess(response.data.recentPosts));
      // @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    } catch (error) {
      console.error(error);
      // @ts-ignore
      dispatch(slice.actions.hasError());
    }
  };
}
// @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
