import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { VariantEnum } from '../../../services/axiosService';
import { CustomerInterface } from '../interfaces/CustomerInterface';
import { CustomersContext } from '../CustomersContext/CustomersContext';
import { CustomersTypesEnum } from '../CustomersContext/CustomersInterface';
import {
  createCustomer,
  CreateCustomerInteface
} from '../services/createCustomer';
import { updateCustomer } from '../services/updateCustomer';
import { ContactTextfield } from './fields/Contact';
import { EmailTextfield } from './fields/Email';
import { FantasyNameTextfield } from './fields/FantasyName';
import { PhoneTextfield } from './fields/Phone';
import { VatNumberTextfield } from './fields/VatNumber';
import { CompanyNameTextfield } from './fields/CompanyName';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import CpfCnpjController, {
  VatNumberCpfCnpjEnum
} from './fields/CpfCnpjController';

interface CustomersFormInterface {
  editCustomers?: Partial<CustomerInterface>;
}

export interface CustomersFormData extends Partial<CreateCustomerInteface> {
  cpfOrCnpj: VatNumberCpfCnpjEnum;
}

export const CustomersForm: React.FC<CustomersFormInterface> = ({
  editCustomers
}) => {
  const [cookies] = useCookies(['authToken']);
  const { enqueueSnackbar } = useSnackbar();
  const { dispatchCustomers } = useContext(CustomersContext);
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    formState
  } = useForm<CustomersFormData>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      cpfOrCnpj: VatNumberCpfCnpjEnum.CNPJ
    }
  });

  const isEditForm = editCustomers !== undefined;

  useEffect(() => {
    editCustomers &&
      Object.entries(editCustomers).forEach(([key, value]) => {
        // @ts-ignore
        setValue(key, value);
      });
  }, [editCustomers, setValue]);

  const handleCreateNewCustomer = async (formData: CustomersFormData) => {
    try {
      const { data, message, variant } = await createCustomer(
        formData,
        cookies.authToken
      );
      enqueueSnackbar(message, { variant });
      dispatchCustomers({
        type: CustomersTypesEnum.ADD_CUSTOMER_TO_STATE,
        customer: data
      });
      dispatchCustomers({
        type: CustomersTypesEnum.OPEN_CUSTOMERS_FORM,
        form: { open: false }
      });
    } catch ({ message }) {
      enqueueSnackbar(message, { variant: VariantEnum.Error });
    }
  };

  const handleEditCustomer = async (formData: CustomersFormData) => {
    if (!editCustomers?.uid) return;

    try {
      const { data, message, variant } = await updateCustomer(
        editCustomers?.uid,
        formData,
        cookies.authToken
      );
      enqueueSnackbar(message, { variant });
      dispatchCustomers({
        type: CustomersTypesEnum.UPDATE_SINGLE_CUSTOMER,
        customer: data
      });
      dispatchCustomers({
        type: CustomersTypesEnum.OPEN_CUSTOMERS_FORM,
        form: { open: false }
      });
    } catch ({ message }) {
      enqueueSnackbar(message, { variant: VariantEnum.Error });
    }
  };

  const onSubmit = (data: CustomersFormData) =>
    isEditForm ? handleEditCustomer(data) : handleCreateNewCustomer(data);

  const isPF = editCustomers?.vat_number?.replace(/^\D+/g, '').length === 11;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2}>
        <CpfCnpjController
          control={control}
          errors={errors}
          defaultValue={
            isPF ? VatNumberCpfCnpjEnum.CPF : VatNumberCpfCnpjEnum.CNPJ
          }
        />
      </Box>

      <CompanyNameTextfield
        defaultValue={editCustomers?.company_name}
        control={control}
        errors={errors}
      />

      <FantasyNameTextfield
        defaultValue={editCustomers?.fantasy_name}
        control={control}
        errors={errors}
      />

      <VatNumberTextfield
        defaultValue={editCustomers?.vat_number}
        control={control}
        errors={errors}
      />

      <EmailTextfield
        defaultValue={editCustomers?.email}
        control={control}
        errors={errors}
      />

      <ContactTextfield
        defaultValue={editCustomers?.contact}
        control={control}
        errors={errors}
      />

      <PhoneTextfield
        defaultValue={editCustomers?.phone}
        control={control}
        errors={errors}
      />

      <LoadingButton
        type="submit"
        variant="text"
        pending={formState.isSubmitting}
        pendingIndicator="Salvando..."
      >
        Salvar
      </LoadingButton>
    </form>
  );
};
