import React, { useContext } from 'react';
import { ProposalsContext } from '../../ProposalsContext/ProposalsContext';
import { ProposalsTypesEnum } from '../../ProposalsContext/ProposalsInterface';
import AmountField from './AmountField';

interface SelectAmountFieldProps {}

const marks = [
  {
    value: 1000,
    label: '1k'
  },
  {
    value: 10000,
    label: '10k'
  },
  {
    value: 25000,
    label: '25k'
  },
  {
    value: 50000,
    label: '50k'
  },
  {
    value: 75000,
    label: '75k'
  },
  {
    value: 100000,
    label: '100k'
  },
  {
    value: 150000,
    label: '150k'
  },
  {
    value: 250000,
    label: '250k'
  },
  {
    value: 500000,
    label: '500k'
  },
  {
    value: 1000000,
    label: '1m'
  },
  {
    value: 3000000,
    label: '3m'
  }
];

const SelectAmountField: React.FC<SelectAmountFieldProps> = () => {
  const { stateProposals, dispatchProposals } = useContext(ProposalsContext);

  return (
    <AmountField
      marks={marks}
      steps={1000}
      onChange={(amount) => {
        dispatchProposals({ type: ProposalsTypesEnum.UPDATE_AMOUNT, amount });
      }}
      disabled={!stateProposals.createProposal.isAmountValid}
      value={stateProposals.createProposal.amount}
      numFormatter={(e: any) => Math.round(e)}
    />
  );
};

export default SelectAmountField;
