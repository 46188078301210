const LOCALE = 'pt-BR';
const OPTIONS = { style: 'currency', currency: 'BRL' };

export function convertFloatToCents(priceInCents: number): string {
  return (priceInCents * 100).toLocaleString(LOCALE, OPTIONS);
}

export function convertCentsToLocale(priceInCents: number): string {
  return (priceInCents / 100).toLocaleString(LOCALE, OPTIONS);
}

export function convertCentsToFloat(priceInCents: number): number {
  return priceInCents / 100;
}

/**
 * Converts a float number to locale
 */
export function convertMoneyToLocale(price: number | string): string {
  return parseFloat(price.toString()).toLocaleString(LOCALE, OPTIONS);
}

/**
 * Converts a float number to cents
 *
 * @param money number | string
 * @example
 */
export function convertMoneyToCents(money: number) {
  return Math.round(money * 100);
}

export function convertCurrencyToNumber(n: String): number {
  if (n === '') return 0;

  const strNumber = n.replace('R$ ', '').split('.').join('').replace(',', '.');

  return parseFloat(strNumber);
}
