import { useHistory } from 'react-router-dom';
import { SimulateType } from './../interfaces/SimulateInterface';
import { createProposal } from './../services/createProposal';
import { simulate } from './../services/simulate';
import { CustomerInterface } from './../../customers/interfaces/CustomerInterface';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { ProposalsContext } from '../ProposalsContext/ProposalsContext';
import { ProposalsTypesEnum } from '../ProposalsContext/ProposalsInterface';
import { PATH_APP } from 'src/routes/paths';

interface UseProposalInterface {
  isProposalValid: boolean;
  isLoading: boolean;
  isCostumerValid: boolean;
  isAmountValid: boolean;
  hasSimulation: boolean;
  selectedCostumer: CustomerInterface | null | undefined;
  selectedSimulation: SimulateType | undefined;
  createProposal: () => Promise<void>;
  simulate: () => Promise<void>;
}

export default function useProposal(): UseProposalInterface {
  const { stateProposals, dispatchProposals } = useContext(ProposalsContext);
  const [cookies] = useCookies(['authToken']);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const isLoading = stateProposals.createProposal.isLoadingSimulation;
  const selectedCostumer = stateProposals.createProposal.selectedCostumer;
  const isCostumerValid = !!selectedCostumer;
  const isAmountValid = stateProposals.createProposal.isAmountValid;
  const isProposalValid = isAmountValid && isCostumerValid;
  const selectedSimulation = stateProposals.createProposal.simulation?.simulate.types.find(
    ({ slug }) => slug === selectedCostumer?.person_type
  );

  return {
    isLoading,
    isProposalValid,
    isCostumerValid,
    isAmountValid,
    hasSimulation: !!stateProposals.createProposal.simulation,
    selectedCostumer,
    selectedSimulation,
    createProposal: async () => {
      if (!isProposalValid) return;
      if (!stateProposals.createProposal.amount) return;
      if (!selectedCostumer?.uid) return;
      if (!stateProposals.createProposal.simulation) return;
      if (!selectedSimulation) return;

      dispatchProposals({
        type: ProposalsTypesEnum.UPDATE_SIMULATION_LOADING,
        isLoading: true
      });

      try {
        const { message, variant, isSuccessful, data } = await createProposal(
          stateProposals.createProposal.amount,
          selectedCostumer?.uid,
          selectedSimulation,
          cookies.authToken
        );

        enqueueSnackbar(message, { variant });

        if (isSuccessful) {
          history.push(`${PATH_APP.proposal.view}/${data.uid}`);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        dispatchProposals({
          type: ProposalsTypesEnum.UPDATE_SIMULATION,
          simulation: undefined
        });
      } finally {
        dispatchProposals({
          type: ProposalsTypesEnum.UPDATE_SIMULATION_LOADING,
          isLoading: false
        });
      }
    },
    simulate: async () => {
      if (!stateProposals?.createProposal?.amount) return;

      dispatchProposals({
        type: ProposalsTypesEnum.UPDATE_SIMULATION_LOADING,
        isLoading: true
      });

      try {
        const response = await simulate(
          stateProposals.createProposal.amount,
          cookies.authToken
        );

        dispatchProposals({
          type: ProposalsTypesEnum.UPDATE_SIMULATION,
          simulation: response.data
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        dispatchProposals({
          type: ProposalsTypesEnum.UPDATE_SIMULATION,
          simulation: undefined
        });
      } finally {
        dispatchProposals({
          type: ProposalsTypesEnum.UPDATE_SIMULATION_LOADING,
          isLoading: false
        });
      }
    }
  };
}
