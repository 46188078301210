// @ts-nocheck
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { Badge } from '@material-ui/core';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
const useStyles = makeStyles((theme) => {
  const createStyle = (color) => {
    return {
      '& .MuiBadge-badge ': {
        // @ts-ignore ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ childre... Remove this comment to see the full error message

        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main
      }
    };
  };
  return {
    colorInfo: createStyle('info'),
    colorSuccess: createStyle('success'),
    colorWarning: createStyle('warning')
  };
});

// ----------------------------------------------------------------------

const MBadge = forwardRef(
  ({ color = 'default', children, className, ...other }, ref) => {
    const classes = useStyles();

    if (
      color === 'default' ||
      color === 'error' ||
      color === 'primary' ||
      color === 'secondary'
    ) {
      return (
        <Badge color={color} className={className} {...other}>
          {children}
        </Badge>
      );
    }

    return (
      <Badge
        className={clsx(
          {
            [classes[`color${capitalize(color)}`]]: color
          },
          className
        )}
        {...other}
      >
        {children}
      </Badge>
    );
  }
);

export default MBadge;
