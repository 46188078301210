import React, { createContext, useReducer } from 'react';
import Reducer, { FeedbackAction } from './FeedbackReducer';
import { feedbackInitialState } from './FeedbackInitializer';
import { FeedbackContextInterface } from './FeedbackInterface';

export type FeedbackDispatchType = React.Dispatch<FeedbackAction>;

interface FeedbackProviderInterface {
  stateFeedback: FeedbackContextInterface;
  dispatchFeedback: FeedbackDispatchType;
}

export const FeedbackContext = createContext<FeedbackProviderInterface>({
  stateFeedback: feedbackInitialState,
  dispatchFeedback: () => console.warn('FeedbackDispatch not ready')
});

const FeedbackProvider: React.FC = ({ children }) => {
  const [stateFeedback, dispatchFeedback] = useReducer(
    Reducer,
    feedbackInitialState
  );

  const globals = {
    stateFeedback,
    dispatchFeedback
  };

  return (
    <FeedbackContext.Provider value={globals}>
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
