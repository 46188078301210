import { hideSnackbarAction } from './actions/hideSnackbarAction';
import { showSnackbarAction } from './actions/showSnackbarAction';
import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  FeedbackContextInterface,
  FeedbackTypesEnum
} from './FeedbackInterface';

export type FeedbackAction = SHOW_SNACKBAR | HIDE_SNACKBAR; // | OTHER_TYPE_GOES_HERE;

function feedbackReducer(
  state: FeedbackContextInterface,
  action: FeedbackAction
): FeedbackContextInterface {
  switch (action.type) {
    case FeedbackTypesEnum.SHOW_SNACKBAR:
      return showSnackbarAction(state, action);

    case FeedbackTypesEnum.HIDE_SNACKBAR:
      return hideSnackbarAction(state, action);

    default:
      return state;
  }
}

export default feedbackReducer;
