import clsx from 'clsx';
import React from 'react';
import { motion } from 'framer-motion';

import { varWrapEnter } from 'src/components/Animate';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {}
}));

type Props = {
  open: boolean;
  children?: React.ReactNode;
};

// @ts-ignore ts-migrate(7031) FIXME: Binding element 'open' implicitly has an 'any' typ... Remove this comment to see the full error message
function MotionContainer({ open, children, className, ...other }: Props) {
  const classes = useStyles();

  return (
    <Box
      component={motion.div}
      initial={false}
      animate={open ? 'animate' : 'exit'}
      variants={varWrapEnter}
      className={clsx(classes.root, className)}
      {...other}
    >
      {children}
    </Box>
  );
}

export default MotionContainer;
