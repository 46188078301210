import React from 'react';
import { Box } from '@material-ui/core';

type Props = {
  className?: string;
};

function Logo({ className, ...other }: Props) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/logo_single.svg"
      height={40}
      className={className}
      {...other}
    />
  );
}

export default Logo;
