/* eslint-disable prettier/prettier */
import clsx from 'clsx';
import React from 'react';

import { motion } from 'framer-motion';
import { varSmallClick } from 'src/components/Animate';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex'
  }
}));

type Props = {
  small?: boolean;
  children?: React.ReactNode;
  className?: string;
};

function ButtonAnimate({ children, className, ...other }: Props) {
  const classes = useStyles();

  return (
    <Box
      component={motion.div}
      whileTap="tap"
      whileHover="hover"
      variants={varSmallClick}
      className={clsx(classes.root, className)}
      {...other}
    >
      {children}
    </Box>
  );
}

export default ButtonAnimate;
