import { axiosPUT } from './../../../services/axiosService';
import { Token } from 'src/services/axiosService';

export const updateUser = async (uid: string, formData: any, token: Token) => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/user/${uid}`;

    const response = await axiosPUT(endpoint, formData, token);

    return {
      ...response,
      data: response.data
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    );
  }
};
