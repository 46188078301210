// @ts-nocheck
import clsx from 'clsx';
import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { ButtonGroup } from '@material-ui/core';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
const useStyles = makeStyles((theme) => {
  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.

  const styleContained = (color) => {
    // @ts-ignore ts-migrate(2493) FIXME: Tuple type 'Shadows' of length '25' has no element... Remove this comment to see the full error message
    return {
      boxShadow: theme.shadows[25][color],
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

      '& .MuiButtonGroup-grouped': {
        color: theme.palette[color].contrastText,
        // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

        backgroundColor: theme.palette[color].main,
        borderColor: `${theme.palette[color].dark} !important`,

        '&:hover': {
          backgroundColor: theme.palette[color].dark
        }
      }
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
    };
  };

  const styleOutlined = (color) => {
    return {
      '& .MuiButtonGroup-grouped': {
        color: theme.palette[color].main,
        borderColor: `${alpha(theme.palette[color].main, 0.48)} !important`,

        '&:hover': {
          borderColor: `${theme.palette[color].main} !important`,

          // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
          backgroundColor: alpha(
            theme.palette[color].main,
            theme.palette.action.hoverOpacity
          )
        }
        // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
        // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
      }
    };
  };

  const styleText = (color) => {
    return {
      '& .MuiButtonGroup-grouped': {
        color: theme.palette[color].main,
        borderColor: `${theme.palette[color].main} !important`,
        '&:hover': {
          backgroundColor: alpha(
            theme.palette[color].main,
            theme.palette.action.hoverOpacity
          )
        }
      }
    };
  };
  return {
    containedInfo: styleContained('info'),
    containedSuccess: styleContained('success'),
    containedWarning: styleContained('warning'),
    containedError: styleContained('error'),

    outlinedInfo: styleOutlined('info'),
    outlinedWarning: styleOutlined('success'),
    outlinedSuccess: styleOutlined('warning'),
    outlinedError: styleOutlined('error'),

    textInfo: styleText('info'),
    textSuccess: styleText('success'),
    textWarning: styleText('warning'),
    textError: styleText('error')
  };
});

type Props = {
  children?: React.ReactNode;
  classes?: object;
  className?: string;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
  variant?: 'contained' | 'outlined' | 'text' | string;
};

// ----------------------------------------------------------------------

function MButtonGroup({
  color = 'primary',
  variant = 'outlined',
  children,
  className,
  ...other
}: Props) {
  const classes = useStyles();

  if (color === 'inherit' || color === 'primary' || color === 'secondary') {
    return (
      <ButtonGroup
        color={color}
        variant={variant}
        className={className}
        {...other}
      >
        {children}
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup
      variant={variant}
      className={clsx(
        // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
        classes[variant],
        {
          [classes[`${variant}${capitalize(color)}`]]: color
        },
        className
      )}
      {...other}
    >
      {/* @ts-ignore ts-migrate(2769) FIXME: No overload matches this call. */}
      {children}
    </ButtonGroup>
  );
}

export default MButtonGroup;
