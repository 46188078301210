import { CustomerInterface } from '../interfaces/CustomerInterface';
import {
  axiosPOST,
  CustomAxiosResponse,
  Token,
  VariantEnum
} from '../../../services/axiosService';

export interface CreateCustomerInteface
  extends Omit<Partial<CustomerInterface>, 'uid'> {}

export const createCustomer = async (
  customerToBeCreated: CreateCustomerInteface,
  token: Token
): Promise<CustomAxiosResponse<CustomerInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/customer`;

    const formattedCustomer: CreateCustomerInteface = {
      ...customerToBeCreated,
      vat_number: customerToBeCreated.vat_number?.match(/\d+/g)?.join('')
    };

    const response = await axiosPOST<CustomerInterface>(
      endpoint,
      formattedCustomer,
      token
    );

    return {
      ...response,
      data: response.data,
      message: `Cliente ${customerToBeCreated.fantasy_name} criado!`,
      variant: VariantEnum.Success
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    );
  }
};
