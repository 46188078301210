// @ts-nocheck
import clsx from 'clsx';

import React, { forwardRef } from 'react';
import { ButtonAnimate } from 'src/components/Animate';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { IconButton } from '@material-ui/core';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

const useStyles = makeStyles((theme) => {
  const createStyle = (color) => {
    return {
      color: theme.palette[color].main,
      '&:hover': {
        backgroundColor: alpha(
          theme.palette[color].main,
          theme.palette.action.hoverOpacity
        )
      }
    };
  };

  return {
    colorInfo: createStyle('info'),
    colorSuccess: createStyle('success'),
    colorWarning: createStyle('warning'),
    colorError: createStyle('error'),
    colorWhite: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.common.white,
          theme.palette.action.hoverOpacity
        )
      }
    }
  };
});

// ----------------------------------------------------------------------

interface MIconButtonInterface {
  color?: string;
  className?: string;
  // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
  size?: string;
  other?: any;
  onClick?: () => void;
}

const MIconButton: React.FC<MIconButtonInterface> = forwardRef(
  ({ color = 'default', children, className, size, ...other }, ref) => {
    // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
    const classes = useStyles();

    if (
      color === 'default' ||
      color === 'inherit' ||
      color === 'primary' ||
      color === 'secondary'
    ) {
      return (
        <ButtonAnimate>
          <IconButton ref={ref} color={color} className={className} {...other}>
            {children}
          </IconButton>
        </ButtonAnimate>
      );
    }

    return (
      <ButtonAnimate>
        <IconButton
          ref={ref}
          className={clsx(
            {
              [classes[`color${capitalize(color)}`]]: color !== 'default'
            },
            className
          )}
          {...other}
        >
          {children}
        </IconButton>
      </ButtonAnimate>
    );
  }
);

export default MIconButton;
