// import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
// import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
// import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';

import React from 'react';
import { useCookies } from 'react-cookie';
import {
  EditableCell,
  ExtendableCellInterface
} from '../../../../components/MUITSDatatable';
import { HTMLElementInputTypeEnum } from '../../../../components/MUITSDatatable/MUITSDatatableInterface';
import TextfieldController, {
  GenericControllerTextfieldInterface
} from '../../../../components/TextfieldController';
import { updateCustomer } from '../../services/updateCustomer';

interface PhoneInterface extends ExtendableCellInterface {}

export const PhoneTextfield: React.FC<
  GenericControllerTextfieldInterface<PhoneInterface>
> = ({ control, errors }) => (
  <TextfieldController
    control={control}
    errors={errors}
    name="phone"
    placeholder="(11) 00000-0000"
    rules={{ required: 'Este campo é obrigatório' }}
    textfieldProps={{ label: 'Telefone' }}
  />
);

export const Phone: React.FC<PhoneInterface> = ({
  tableMeta,
  updateValue,
  value
}): JSX.Element => {
  const [cookies] = useCookies(['authToken']);
  const id = tableMeta.rowData[0];

  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    updateCustomer(id, { phone: valueToUpdate }, cookies.authToken);
  };

  return (
    <EditableCell
      type={HTMLElementInputTypeEnum.Text}
      value={value}
      tableMeta={tableMeta}
      updateValue={updateValue}
      handleUpdate={handleUpdate}
      rules={{
        required: 'Descrição obrigatório'
      }}
    />
  );
};

export default Phone;
