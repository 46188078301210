import {
  Box,
  Card,
  Grid,
  CardContent,
  Button,
  Container
} from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Page from 'src/components/Page';
import { HeaderDashboard } from 'src/layouts/Common';
import { PATH_APP } from 'src/routes/paths';
import { ProposalsForm } from '../form/ProposalsForm';
import useProposal from '../hooks/useProposal';
import ProposalSimulate from './ProposalSimulate';

interface ProposalCreateProps {}

const ProposalCreate: React.FC<ProposalCreateProps> = () => {
  const {
    hasSimulation,
    isLoading,
    isProposalValid,
    createProposal
  } = useProposal();

  return (
    <>
      <Page title="Nova proposta | Ockbank">
        <Container>
          <HeaderDashboard
            heading="Nova proposta"
            links={[
              { name: 'Dashboard', href: PATH_APP.root },
              { name: 'Propostas', href: PATH_APP.proposal.list },
              { name: 'Criar' }
            ]}
          />

          <Grid container spacing={3}>
            <Grid item xs={12} md={4} order={!isMobile ? 0 : 1}>
              <ProposalSimulate />
            </Grid>

            <Grid item xs={12} md={8} order={isMobile ? 0 : 1}>
              <Card>
                <CardContent>
                  <ProposalsForm />
                </CardContent>
              </Card>

              <Box mt={2}>
                {hasSimulation && (
                  <Button
                    onClick={createProposal}
                    disabled={!isProposalValid || isLoading}
                    variant="contained"
                    fullWidth
                  >
                    Criar proposta
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default ProposalCreate;
