import { updateSimulationLoadingAction } from './actions/updateSimulationLoadingAction';
import {
  ProposalsAction,
  ProposalsContextInterface,
  ProposalsTypesEnum
} from './ProposalsInterface';
import { updateStateAction } from './actions/updateStateAction';
import { updateProposalsListAction } from './actions/updateProposalsListAction';
import { updateSelectedCostumerAction } from './actions/updateSelectedCostumerAction';
import { updateAmountAction } from './actions/updateAmountAction';
import { updateSimulationAction } from './actions/updateSimulationAction';
import { addSwrPropsAction } from './actions/addSwrPropsAction';
import { updateSingleProposalAction } from './actions/updateSingleProposalAction';

function updateStateReducer(
  state: ProposalsContextInterface,
  action: ProposalsAction
): ProposalsContextInterface {
  switch (action.type) {
    case ProposalsTypesEnum.UPDATE_STATE:
      return updateStateAction(state, action);

    case ProposalsTypesEnum.UPDATE_PROPOSALS_LIST:
      return updateProposalsListAction(state, action);

    case ProposalsTypesEnum.UPDATE_SELECTED_COSTUMER:
      return updateSelectedCostumerAction(state, action);

    case ProposalsTypesEnum.UPDATE_AMOUNT:
      return updateAmountAction(state, action);

    case ProposalsTypesEnum.UPDATE_SIMULATION:
      return updateSimulationAction(state, action);

    case ProposalsTypesEnum.UPDATE_SIMULATION_LOADING:
      return updateSimulationLoadingAction(state, action);

    case ProposalsTypesEnum.ADD_SWR_PROPS:
      return addSwrPropsAction(state, action);

    case ProposalsTypesEnum.UPDATE_SINGLE_PROPOSAL:
      return updateSingleProposalAction(state, action);

    default:
      return state;
  }
}

export default updateStateReducer;
