import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { getInitialize } from 'src/redux/slices/authJwt';

type Props = {
  children?: React.ReactNode;
};

function JwtProvider({ children }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInitialize());
  }, [dispatch]);

  return <>{children}</>;
}

export default JwtProvider;
