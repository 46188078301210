import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../contexts/LayoutContext/LayoutContext';
import { LayoutTypesEnum } from '../../contexts/LayoutContext/LayoutInterface';
import ProposalsProvider from './ProposalsContext/ProposalsContext';

interface ProvidersRootPageInterface {}

const ProposalsRootPage: React.FC<ProvidersRootPageInterface> = ({
  children
}) => {
  const { dispatchLayout } = useContext(LayoutContext);

  useEffect(() => {
    dispatchLayout({
      type: LayoutTypesEnum.CONFIGURE_PAGE,
      page: {
        title: 'Propostas'
      }
    });
  }, [dispatchLayout]);

  return <ProposalsProvider>{children}</ProposalsProvider>;
};

export default ProposalsRootPage;
