import {
  CustomersContextInterface,
  CustomersFormInterface,
  CustomersFormTypeEnum,
  CustomersTypesEnum
} from '../CustomersInterface';

export type OPEN_CUSTOMERS_FORM = {
  type: CustomersTypesEnum.OPEN_CUSTOMERS_FORM;
  form: Omit<CustomersFormInterface, 'type'>;
};

export function openCustomersFormAction(
  state: CustomersContextInterface,
  action: OPEN_CUSTOMERS_FORM
): CustomersContextInterface {
  return {
    ...state,
    form: {
      ...action.form,
      type:
        action.form.editCustomer === undefined
          ? CustomersFormTypeEnum.Create
          : CustomersFormTypeEnum.Edit
    }
  };
}
