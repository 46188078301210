interface SnackbarInterface {
  message: string;
  variant?: string;
  timestamp?: number;
}

export interface FeedbackContextInterface {
  snackbar?: SnackbarInterface;
}

export enum FeedbackTypesEnum {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR
}

export type SHOW_SNACKBAR = {
  type: FeedbackTypesEnum.SHOW_SNACKBAR;
  message: string;
  variant?: string;
};

export type HIDE_SNACKBAR = {
  type: FeedbackTypesEnum.HIDE_SNACKBAR;
};
