import { ProposalsContextInterface } from './ProposalsInterface';

export const proposalsInitialState: ProposalsContextInterface = {
  proposals: [],
  // revalidate: async () => Resolve,
  createProposal: {
    selectedCostumer: undefined,
    amount: undefined,
    isAmountValid: true,
    simulation: undefined,
    isLoadingSimulation: false
  }
};
