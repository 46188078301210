import { OckUserInterface } from 'src/views/auth/interfaces/UserInterface';
import { UsersContextInterface, UsersTypesEnum } from '../UsersInterface';

export type ADD_CUSTOMER_TO_STATE = {
  type: UsersTypesEnum.ADD_CUSTOMER_TO_STATE;
  user: OckUserInterface;
};

export function addUserToStateAction(
  state: UsersContextInterface,
  action: ADD_CUSTOMER_TO_STATE
): UsersContextInterface {
  return {
    ...state,
    users: [...state.users, action.user]
  };
}
