import { CustomerInterface } from '../../interfaces/CustomerInterface';
import {
  CustomersContextInterface,
  CustomersTypesEnum
} from '../CustomersInterface';

export type ADD_CUSTOMER_TO_STATE = {
  type: CustomersTypesEnum.ADD_CUSTOMER_TO_STATE;
  customer: CustomerInterface;
};

export function addCustomerToStateAction(
  state: CustomersContextInterface,
  action: ADD_CUSTOMER_TO_STATE
): CustomersContextInterface {
  return {
    ...state,
    customers: [...state.customers, action.customer],
    lastCreatedCustomer: action.customer
  };
}
