import { CustomerInterface } from '../interfaces/CustomerInterface';
import {
  axiosGET,
  CustomAxiosResponse,
  Token,
  VariantEnum
} from '../../../services/axiosService';

export const listCustomers = async (
  token: Token
): Promise<CustomAxiosResponse<CustomerInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/customer`;

    const response = await axiosGET<CustomerInterface[]>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
      variant: VariantEnum.Success
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    );
  }
};
