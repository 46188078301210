import React from 'react';

import { MIcon } from 'src/theme';

import { PATH_ADMIN, PATH_APP } from 'src/routes/paths';
import AdminProtect from 'src/components/Auth/AdminProtect';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,

  blog: <MIcon src={path('ic_blog')} />,

  calendar: <MIcon src={path('ic_calendar')} />,

  cart: <MIcon src={path('ic_cart')} />,

  charts: <MIcon src={path('ic_charts')} />,

  chat: <MIcon src={path('ic_chat')} />,

  components: <MIcon src={path('ic_components')} />,

  dashboard: <MIcon src={path('ic_dashboard')} />,

  editor: <MIcon src={path('ic_editor')} />,

  elements: <MIcon src={path('ic_elements')} />,

  error: <MIcon src={path('ic_error')} />,

  mail: <MIcon src={path('ic_mail')} />,

  map: <MIcon src={path('ic_map')} />,

  page: <MIcon src={path('ic_page')} />,

  user: <MIcon src={path('ic_user')} />,

  upload: <MIcon src={path('ic_upload')} />,

  copy: <MIcon src={path('ic_copy')} />,

  carousel: <MIcon src={path('ic_carousel')} />,

  language: <MIcon src={path('ic_language')} />
};

interface Item {
  title: string;
  icon: JSX.Element;
  href: string;
}

interface NavConfigInterface {
  subheader: string;
  guard?: (JSX.Element | Element | any)[];
  items: Item[];
}

const navConfig: NavConfigInterface[] = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Geral',
    items: [
      {
        title: 'dashboard',
        icon: ICONS.dashboard,
        href: PATH_APP.general.root
      },
      {
        title: 'Clientes',
        icon: ICONS.user,
        href: PATH_APP.customers.list
      },
      {
        title: 'Proposta',
        icon: ICONS.cart,
        href: PATH_APP.proposal.list
      }
    ]
  },
  // ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Administração',
    guard: [AdminProtect],
    items: [
      {
        title: 'Clientes',
        icon: ICONS.authenticator,
        href: PATH_ADMIN.clients
      }
    ]
  }
];

export default navConfig;
