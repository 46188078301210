import { CustomersContextInterface } from './CustomersInterface';

export const customersInitialState: CustomersContextInterface = {
  customers: [],
  lastCreatedCustomer: null,
  form: {
    open: false,
    editCustomer: undefined
  }
};
