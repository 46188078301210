/* eslint-disable import/no-anonymous-default-export */
export default {
  apiKey: 'AIzaSyAtcXRQv6OB8uBNvqJ76ksnqq0tESdRtXw',
  authDomain: 'ockbank-plataforma.firebaseapp.com',
  projectId: 'ockbank-plataforma',
  storageBucket: 'ockbank-plataforma.appspot.com',
  messagingSenderId: '66529257985',
  appId: '1:66529257985:web:ffb8ca09962b9e46dfae00',
  measurementId: 'G-F95JC4QS9Z'
};
