import { googleAnalyticsConfig } from 'src/config';
// @ts-nocheck
// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
const setup = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  // @ts-ignore ts-migrate(2339) FIXME: Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
  // @ts-ignore ts-migrate(2339) FIXME: Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
  if (!window.gtag) {
    // @ts-ignore ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
    return;
  }
  // @ts-ignore ts-migrate(2339) FIXME: Property 'gtag' does not exist on type 'Window & t... Remove this comment to see the full error message
  window.gtag(...args);
};

const track = {
  pageview: (props: any) => {
    setup('config', googleAnalyticsConfig, props);
  },
  event: (type: any, props: any) => {
    setup('event', type, props);
  }
};

export default track;
