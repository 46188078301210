import { OckUserInterface } from './../interfaces/UserInterface';
import { listUserInfo } from './listUserInfo';
import {
  CustomAxiosResponse,
  VariantEnum,
  axiosPOST
} from './../../../services/axiosService';

export interface LoginInterface {
  token: string;
}

export interface LoginResponseInterface {
  token: string;
  user: OckUserInterface;
  firebaseUser?: any;
}

export const login = async (
  tokenFirebase: string
): Promise<CustomAxiosResponse<LoginResponseInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/auth`;

    const body = {
      token_firebase: tokenFirebase
    };

    const response = await axiosPOST<LoginResponseInterface>(
      endpoint,
      body,
      undefined
    );

    const userResponse = await listUserInfo(response.data.token);

    return {
      ...userResponse,
      data: {
        token: response.data.token,
        user: userResponse.data
      },
      message: `Bem vindo ${userResponse.data.name}!`,
      variant: VariantEnum.Success
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, ao criar o usuário.'
    );
  }
};
