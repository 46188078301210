import { UPDATE_CUSTOMER_FIELD } from './actions/updateCustomerFieldAction';
import { OPEN_CUSTOMERS_FORM } from './actions/openCustomersFormAction';
import { UPDATE_CUSTOMERS_LIST } from './actions/updateCustomersListAction';
import { ADD_CUSTOMER_TO_STATE } from './actions/addCustomerToStateAction';
import { CustomerInterface } from '../interfaces/CustomerInterface';
import { UPDATE_STATE } from './actions/updateStateAction';
import { UPDATE_SINGLE_CUSTOMER } from './actions/updateSingleCustomerAction';

export enum CustomersFormTypeEnum {
  Create,
  Edit
}

export interface CustomersFormInterface {
  open: boolean;
  editCustomer?: CustomerInterface;
  type?: CustomersFormTypeEnum;
}

export interface CustomersContextInterface {
  customers: CustomerInterface[];
  lastCreatedCustomer: CustomerInterface | undefined | null;
  form: CustomersFormInterface;
}

export type CustomersAction =
  | UPDATE_STATE
  | ADD_CUSTOMER_TO_STATE
  | OPEN_CUSTOMERS_FORM
  | UPDATE_CUSTOMER_FIELD
  | UPDATE_SINGLE_CUSTOMER
  | UPDATE_CUSTOMERS_LIST;

export enum CustomersTypesEnum {
  UPDATE_STATE,
  ADD_CUSTOMER_TO_STATE,
  UPDATE_CUSTOMERS_LIST,
  OPEN_CUSTOMERS_FORM,
  UPDATE_SINGLE_CUSTOMER,
  UPDATE_CUSTOMER_FIELD
}
