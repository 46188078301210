import {
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
  Token
} from './../../../services/axiosService';
import { OckUserInterface } from './../interfaces/UserInterface';

export const listUserInfo = async (
  token: Token
): Promise<CustomAxiosResponse<OckUserInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/user`;

    const response = await axiosGET<OckUserInterface>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
      variant: VariantEnum.Success
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    );
  }
};
