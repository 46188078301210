import { Box, Container, Typography, Button } from '@material-ui/core';
import React from 'react';
import { MotionContainer, varBounce } from 'src/components/Animate';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

interface EmptyPlaceholderInterface {
  title: string;
  subtitle: string;
  path?: string;
  buttonTitle?: string;
  onClick?: () => void;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderInterface> = ({
  title,
  subtitle,
  path,
  buttonTitle,
  onClick
}) => {
  const history = useHistory();

  return (
    <Container>
      {/* @ts-ignore ts-migrate(2322) FIXME: Type '{ children: Element; initial: string; open: ... Remove this comment to see the full error message */}
      <MotionContainer initial="initial" open>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <motion.div variants={varBounce}>
            <Typography variant="h3" gutterBottom>
              {title}
            </Typography>
          </motion.div>

          <Box mb={5}>
            <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
          </Box>

          {/* <Box
            component={motion.img}
            variants={varBounceIn}
            alt="404"
            src="/static/illustrations/illustration_register.svg"
            sx={{ width: '100%', maxHeight: 240, my: { xs: 5, sm: 10 } }}
          /> */}

          {buttonTitle && (
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                if (onClick) onClick();
                if (path) history.push(path);
              }}
              // component={RouterLink}
            >
              {buttonTitle}
            </Button>
          )}
        </Box>
      </MotionContainer>
    </Container>
  );
};

export default EmptyPlaceholder;
