import React from 'react';

// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Helmet } from 'react-helmet';

import { googleAnalyticsConfig } from 'src/config';

// ----------------------------------------------------------------------

const GA_MEASUREMENT_ID = googleAnalyticsConfig;

function GoogleAnalytics() {
  return (
    <Helmet>
      <script
        type="module"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />

      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script>
    </Helmet>
  );
}

export default GoogleAnalytics;
