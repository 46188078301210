// import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
// import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
// import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';

import React from 'react';
import { useCookies } from 'react-cookie';
import {
  EditableCell,
  ExtendableCellInterface
} from '../../../../components/MUITSDatatable';
import { HTMLElementInputTypeEnum } from '../../../../components/MUITSDatatable/MUITSDatatableInterface';
import TextfieldController, {
  GenericControllerTextfieldInterface
} from '../../../../components/TextfieldController';
import { updateCustomer } from '../../services/updateCustomer';
import { VatNumberCpfCnpjEnum } from './CpfCnpjController';

interface FantasyNameInterface extends ExtendableCellInterface {}

export const FantasyNameTextfield: React.FC<
  GenericControllerTextfieldInterface<FantasyNameInterface>
> = ({ control, errors, defaultValue }) => {
  const isPJ = control.getValues('cpfOrCnpj') === VatNumberCpfCnpjEnum.CNPJ;

  return (
    <TextfieldController
      control={control}
      errors={errors}
      defaultValue={defaultValue}
      name="fantasy_name"
      placeholder={isPJ ? 'Nome fantasia' : 'Nome'}
      rules={{ required: 'Este campo é obrigatório!' }}
      textfieldProps={{ label: isPJ ? 'Nome fantasia' : 'Nome' }}
    />
  );
};

export const FantasyName: React.FC<FantasyNameInterface> = ({
  tableMeta,
  updateValue,
  value
}): JSX.Element => {
  const [cookies] = useCookies(['authToken']);
  const id = tableMeta.rowData[0];

  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    updateCustomer(id, { fantasy_name: valueToUpdate }, cookies.authToken);
  };

  return (
    <EditableCell
      type={HTMLElementInputTypeEnum.Text}
      value={value}
      tableMeta={tableMeta}
      updateValue={updateValue}
      handleUpdate={handleUpdate}
      rules={{
        required: 'Descrição obrigatório'
      }}
    />
  );
};

export default FantasyName;
