import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
// ----------------------------------------------------------------------
hljs.configure({
  languages: ['javascript', 'jsx', 'sh', 'html', 'css', 'bash', 'json']
});

// @ts-ignore ts-migrate(2339) FIXME: Property 'hljs' does not exist on type 'Window & t... Remove this comment to see the full error message
// @ts-ignore
window.hljs = hljs;
