import { OckUserInterface } from './../../auth/interfaces/UserInterface';
import { UPDATE_CUSTOMER_FIELD } from './actions/updateUserFieldAction';
import { OPEN_CUSTOMERS_FORM } from './actions/openUsersFormAction';
import { UPDATE_CUSTOMERS_LIST } from './actions/updateUsersListAction';
import { ADD_CUSTOMER_TO_STATE } from './actions/addUserToStateAction';
import { UPDATE_STATE } from './actions/updateStateAction';
import { UPDATE_SINGLE_CUSTOMER } from './actions/updateSingleUserAction';

export enum UsersFormTypeEnum {
  Create,
  Edit
}

export interface UsersFormInterface {
  open: boolean;
  editUser?: OckUserInterface;
  type?: UsersFormTypeEnum;
}

export interface UsersContextInterface {
  users: OckUserInterface[];
  form: UsersFormInterface;
}

export type UsersAction =
  | UPDATE_STATE
  | ADD_CUSTOMER_TO_STATE
  | OPEN_CUSTOMERS_FORM
  | UPDATE_CUSTOMER_FIELD
  | UPDATE_SINGLE_CUSTOMER
  | UPDATE_CUSTOMERS_LIST;

export enum UsersTypesEnum {
  UPDATE_STATE,
  ADD_CUSTOMER_TO_STATE,
  UPDATE_CUSTOMERS_LIST,
  OPEN_CUSTOMERS_FORM,
  UPDATE_SINGLE_CUSTOMER,
  UPDATE_CUSTOMER_FIELD
}
