import {
  ProposalsContextInterface,
  ProposalsTypesEnum
} from '../ProposalsInterface';

export type UPDATE_SIMULATION_LOADING = {
  type: ProposalsTypesEnum.UPDATE_SIMULATION_LOADING;
  isLoading: boolean;
};

export function updateSimulationLoadingAction(
  state: ProposalsContextInterface,
  action: UPDATE_SIMULATION_LOADING
): ProposalsContextInterface {
  return {
    ...state,
    createProposal: {
      ...state.createProposal,
      isLoadingSimulation: action.isLoading
    }
  };
}
