import {
  CustomersContextInterface,
  CustomersTypesEnum
} from '../CustomersInterface';

export type UPDATE_STATE = {
  type: CustomersTypesEnum.UPDATE_STATE;
  newState: Partial<CustomersContextInterface>;
};

export function updateStateAction(
  state: CustomersContextInterface,
  action: UPDATE_STATE
): CustomersContextInterface {
  return {
    ...state,
    ...action.newState
  };
}
