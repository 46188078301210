import {
  ProposalsContextInterface,
  ProposalsTypesEnum
} from '../ProposalsInterface';

export type ADD_SWR_PROPS = {
  type: ProposalsTypesEnum.ADD_SWR_PROPS;
  revalidate: () => Promise<boolean>;
};

export function addSwrPropsAction(
  state: ProposalsContextInterface,
  action: ADD_SWR_PROPS
): ProposalsContextInterface {
  return {
    ...state,
    revalidate: action.revalidate
  };
}
