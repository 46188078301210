import React, { useCallback, useContext } from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import { CustomerInterface } from '../interfaces/CustomerInterface';
import { MUITSDatatable } from '../../../components/MUITSDatatable';
import CompanyName from './fields/CompanyName';
import FantasyName from './fields/FantasyName';
import Contact from './fields/Contact';
import Phone from './fields/Phone';
import Email from './fields/Email';
import { Box, Button, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { CustomersContext } from '../CustomersContext/CustomersContext';
import { CustomersTypesEnum } from '../CustomersContext/CustomersInterface';

interface CustomersTableInterface {
  customers: CustomerInterface[];
}

interface TableCustomersColumn extends Omit<MUIDataTableColumn, 'name'> {
  // name: keyof CustomerInterface;
  name: string;
}

const CustomersTable: React.FC<CustomersTableInterface> = ({ customers }) => {
  const { stateCustomers, dispatchCustomers } = useContext(CustomersContext);

  const handleCreateFornecedor = () =>
    dispatchCustomers({
      type: CustomersTypesEnum.OPEN_CUSTOMERS_FORM,
      form: { open: true }
    });

  const handleEditFornecedor = useCallback(
    (customer: CustomerInterface) =>
      dispatchCustomers({
        type: CustomersTypesEnum.OPEN_CUSTOMERS_FORM,
        form: { open: true, editCustomer: customer }
      }),
    [dispatchCustomers]
  );

  const columns: TableCustomersColumn[] = [
    {
      name: 'uid',
      label: 'UID',
      options: {
        searchable: false,
        display: false
      }
    },
    {
      name: 'company_name',
      label: 'Razão social',
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <CompanyName
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
          />
        )
      }
    },
    {
      name: 'fantasy_name',
      label: 'Nome',
      options: {
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <FantasyName
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
          />
        )
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <Email
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
          />
        )
      }
    },
    {
      name: 'contact',
      label: 'Responsável',
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <Contact
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
          />
        )
      }
    },
    {
      name: 'phone',
      label: 'Telefone',
      options: {
        customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
          <Phone
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
          />
        )
      }
    },
    {
      name: 'vat_number',
      label: 'CPF/CNPJ',
      options: {
        display: false
      }
    },
    // {
    //   name: 'address',
    //   label: 'Endereço',
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
    //       <Address
    //         value={value}
    //         tableMeta={tableMeta}
    //         updateValue={updateValue}
    //       />
    //     ),
    //     display: false
    //   }
    // },
    {
      name: 'action',
      label: 'Ação',
      options: {
        searchable: false,
        display: true,
        customBodyRenderLite: (dataIndex, bla) => {
          return (
            <Box display="flex" flexDirection="row">
              <IconButton
                onClick={() =>
                  handleEditFornecedor(stateCustomers.customers[dataIndex])
                }
              >
                <Edit />
              </IconButton>
              {/* <RemoveCustomerButton
                customer={stateCustomers.customers[dataIndex]}
              /> */}
            </Box>
          );
        }
      }
    }
  ];

  return (
    <MUITSDatatable
      title=""
      data={customers}
      columns={columns}
      options={{
        customToolbar: () => (
          <Button
            onClick={handleCreateFornecedor}
            variant="outlined"
            size="small"
          >
            Novo
          </Button>
        )
      }}
    />
  );
};

export default CustomersTable;
