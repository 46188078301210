import React, { useEffect } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Box, IconButton } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { isMobile } from 'react-device-detect';
import { Close } from '@material-ui/icons';

interface DialogFormButton {
  label: string;
  props: ButtonProps;
}

interface DialogFormInterface {
  title: string;
  actionsButton?: DialogFormButton[];
  open?: boolean;
  onClose?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogForm: React.FC<DialogFormInterface> = ({
  children,
  title,
  actionsButton = [],
  open = true,
  onClose = () => {}
}) => {
  const [localOpen, setLocalOpen] = React.useState(open);

  useEffect(() => setLocalOpen(open), [open]);

  const handleClose = () => {
    setLocalOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        open={localOpen}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
        keepMounted={false}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            {title}
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {actionsButton.map(({ label, props }, index) => (
            <Button key={index} {...props}>
              {label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogForm;
