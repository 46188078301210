// @ts-nocheck
import clsx from 'clsx';
import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { Switch } from '@material-ui/core';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

const useStyles = makeStyles((theme) => {
  const createStyle = (color) => {
    return {
      '& .Mui-checked': {
        color: theme.palette[color].main,
        '&:hover': {
          backgroundColor: alpha(
            theme.palette[color].main,
            theme.palette.action.hoverOpacity
          )
        }
      },

      '& .Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette[color].main
      }
    };
  };

  return {
    colorInfo: createStyle('info'),
    colorSuccess: createStyle('success'),
    colorWarning: createStyle('warning'),
    colorError: createStyle('error')
  };
});

type Props = {
  classes?: object;
  className?: string;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
};

// ----------------------------------------------------------------------

function MSwitch({ color = 'primary', className, ...other }: Props) {
  const classes = useStyles();

  if (color === 'default' || color === 'primary' || color === 'secondary') {
    return <Switch color={color} className={className} {...other} />;
  }

  return (
    <Switch
      className={clsx(
        {
          [classes[`color${capitalize(color)}`]]: color
        },

        className
      )}
      {...other}
    />
  );
}

export default MSwitch;
