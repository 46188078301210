// ----------------------------------------------------------------------

export default function Typography({ theme }: any) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: { marginBottom: 16 },
        gutterBottom: { marginBottom: 8 }
      }
    }
  };
}
