import { OckUserInterface } from './../../../auth/interfaces/UserInterface';
import { UsersContextInterface, UsersTypesEnum } from '../UsersInterface';

export type UPDATE_SINGLE_CUSTOMER = {
  type: UsersTypesEnum.UPDATE_SINGLE_CUSTOMER;
  user: OckUserInterface;
};

export function updateSingleUserAction(
  state: UsersContextInterface,
  action: UPDATE_SINGLE_CUSTOMER
): UsersContextInterface {
  const newUsers = state.users.map((user) =>
    action.user.uid === user.uid ? action.user : user
  );

  return {
    ...state,
    users: newUsers
  };
}
