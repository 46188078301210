import {
  axiosPOST,
  CustomAxiosResponse,
  Token
} from './../../../services/axiosService';
import { ProposalInterface } from '../interfaces/ProposalInterface';
import { SimulateType } from '../interfaces/SimulateInterface';

export interface CreateProposalInteface
  extends Omit<Partial<ProposalInterface>, 'uid'> {}

export const createProposal = async (
  amount: number,
  customerUid: String,
  simulation: SimulateType,
  token: Token
): Promise<CustomAxiosResponse<ProposalInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/proposal`;

    const response = await axiosPOST<ProposalInterface>(
      endpoint,
      {
        amount,
        customer_uid: customerUid,
        simulation
      },
      token
    );

    return {
      ...response,
      data: response.data
      // message: `Sua proposta foi criada com sucesso!`,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    );
  }
};
