/* eslint-disable @typescript-eslint/no-unused-vars */
import { LOGOUT_USER, AuthContextInterface } from '../AuthInterface';

export function logoutUserAction(
  state: AuthContextInterface,
  action: LOGOUT_USER
): AuthContextInterface {
  return {
    ...state,
    loggedUser: undefined,
    firebaseUser: null
  };
}
