import React, { createContext, useReducer } from 'react';
import Reducer from './ProposalsReducer';
import { proposalsInitialState } from './ProposalsInitializer';
import {
  ProposalsContextInterface,
  ProposalsAction
} from './ProposalsInterface';

export type ProposalsDispatchType = React.Dispatch<ProposalsAction>;

interface ProposalsProviderInterface {
  stateProposals: ProposalsContextInterface;
  dispatchProposals: ProposalsDispatchType;
}

export const ProposalsContext = createContext<ProposalsProviderInterface>({
  stateProposals: proposalsInitialState,
  dispatchProposals: () => console.warn('ProposalsDispatch not ready')
});

const ProposalsProvider: React.FC = ({ children }) => {
  const [stateProposals, dispatchProposals] = useReducer(
    Reducer,
    proposalsInitialState
  );

  const globals = {
    stateProposals,
    dispatchProposals
  };

  return (
    <ProposalsContext.Provider value={globals}>
      {children}
    </ProposalsContext.Provider>
  );
};

export default ProposalsProvider;
