// ----------------------------------------------------------------------

export default function Popover({ theme }: any) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.shadows[25].z12
        }
      }
    }
  };
}
