import NavBar from './NavBar';

import TopBar from './TopBar';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomersProvider from '../../views/customers/CustomersContext/CustomersContext';
import ProposalsProvider from '../../views/proposals/ProposalsContext/ProposalsContext';
import UsersProvider from '../../views/users/UsersContext/UsersContext';
import CustomersFormDialog from 'src/views/customers/layouts/CustomersFormDialog';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
  },
  main: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 40,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 5,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
}));

type Props = {
  children?: React.ReactNode;
};

function DashboardLayout({ children }: Props) {
  const classes = useStyles();

  const [openNav, setOpenNav] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onOpenNav={() => setOpenNav(true)} />
      <NavBar onCloseNav={() => setOpenNav(false)} isOpenNav={openNav} />

      <CustomersProvider>
        <ProposalsProvider>
          <div className={classes.main}>{children}</div>
          <CustomersFormDialog />
        </ProposalsProvider>
      </CustomersProvider>
    </div>
  );
}

export function AdminLayout({ children }: Props) {
  const classes = useStyles();

  const [openNav, setOpenNav] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onOpenNav={() => setOpenNav(true)} />
      <NavBar onCloseNav={() => setOpenNav(false)} isOpenNav={openNav} />

      <UsersProvider>
        <div className={classes.main}>{children}</div>
      </UsersProvider>
    </div>
  );
}

export default DashboardLayout;
