// @ts-nocheck
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Chip } from '@material-ui/core';
import { capitalize } from '@material-ui/core/utils';
import { alpha, makeStyles, emphasize } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

const useStyles = makeStyles((theme) => {
  // Filled

  const styleFilled = (color) => {
    return {
      backgroundColor: theme.palette[color].main,
      color: theme.palette[color].contrastText,
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

      '& .MuiChip-deleteIcon': {
        color: alpha(theme.palette[color].contrastText, 0.7),
        // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

        '&:hover, &:active': {
          color: theme.palette[color].contrastText
        }
        // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
      },

      '& .MuiChip-avatar': {
        color: theme.palette[color].lighter,

        backgroundColor: theme.palette[color].dark
      },

      '& .MuiChip-icon': {
        color: 'inherit'
      }
    };
    // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
  };
  const styleFilledClickable = (color) => {
    return {
      '&:hover, &:focus': {
        backgroundColor: emphasize(
          theme.palette[color].main,
          theme.palette.action.hoverOpacity
        )
      }
    };
    // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
  };
  const styleFilledDeletable = (color) => {
    return {
      '&:focus': {
        backgroundColor: emphasize(
          theme.palette[color].main,
          theme.palette.action.focusOpacity
        )
      }
    };
  };

  // @ts-ignore ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ childre... Remove this comment to see the full error message
  // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
  // @ts-ignore ts-migrate(2339) FIXME: Property 'onDelete' does not exist on type '{ chil... Remove this comment to see the full error message
  // Outlined
  // @ts-ignore ts-migrate(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message

  const styleOutlined = (color) => {
    return {
      // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.

      color: theme.palette[color].main,
      border: `1px solid ${theme.palette[color].main}`,
      // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
      backgroundColor: 'transparent',
      '&:focus, &.MuiChip-clickable:hover': {
        backgroundColor: alpha(
          theme.palette[color].main,

          theme.palette.action.hoverOpacity
        )
      },

      '& .MuiChip-deleteIcon': {
        color: alpha(theme.palette[color].main, 0.7),

        '&:hover, &:active': {
          color: theme.palette[color].main
        }
      }
    };
  };

  return {
    // Filled
    colorInfo: styleFilled('info'),
    clickableColorInfo: styleFilledClickable('info'),
    deletableColorInfo: styleFilledDeletable('info'),

    colorSuccess: styleFilled('success'),
    clickableColorSuccess: styleFilledClickable('success'),
    deletableColorSuccess: styleFilledDeletable('success'),

    colorWarning: styleFilled('warning'),
    clickableColorWarning: styleFilledClickable('warning'),
    deletableColorWarning: styleFilledDeletable('warning'),

    colorError: styleFilled('error'),
    clickableColorError: styleFilledClickable('error'),
    deletableColorError: styleFilledDeletable('error'),

    // Outlined
    outlinedInfo: styleOutlined('info'),
    outlinedSuccess: styleOutlined('success'),
    outlinedWarning: styleOutlined('warning'),
    outlinedError: styleOutlined('error')
  };
});

// ----------------------------------------------------------------------

const Mchip = forwardRef(
  (
    {
      color = 'default',
      variant = 'filled',
      clickable: clickableProp,
      onDelete: onDeleteProp,
      className,
      ...other
    },
    ref
  ) => {
    const classes = useStyles();

    if (color === 'default' || color === 'primary' || color === 'secondary') {
      return (
        // @ts-ignore ts-migrate(2339) FIXME: Property 'clickable' does not exist on type 'Props... Remove this comment to see the full error message
        <Chip
          ref={ref}
          // @ts-ignore ts-migrate(2339) FIXME: Property 'onDelete' does not exist on type 'PropsW... Remove this comment to see the full error message
          color={color}
          variant={variant}
          clickable={clickableProp && clickableProp}
          onDelete={onDeleteProp && onDeleteProp}
          className={className}
          {...other}
        />
      );
    }

    return (
      <Chip
        ref={ref}
        // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
        variant={variant}
        clickable={clickableProp && clickableProp}
        onDelete={onDeleteProp && onDeleteProp}
        className={clsx(
          classes[variant],
          {
            [classes[`color${capitalize(color)}`]]: color !== 'default',
            [classes[`clickableColor${capitalize(color)}`]]:
              clickableProp && color !== 'default',

            [classes[`deletableColor${capitalize(color)}`]]:
              onDeleteProp && color !== 'default',
            // @ts-ignore ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
            [classes.outlinedInfo]: variant === 'outlined' && color === 'info',
            // @ts-ignore ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
            [classes.outlinedSuccess]:
              variant === 'outlined' && color === 'success',
            [classes.outlinedWarning]:
              // @ts-ignore ts-migrate(2367) FIXME: This condition will always return 'true' since the... Remove this comment to see the full error message
              variant === 'outlined' && color === 'warning',
            [classes.outlinedError]: variant === 'outlined' && color === 'error'
          },
          className
        )}
        {...other}
      />
    );
  }
);

export default Mchip;
