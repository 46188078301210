import React, { useContext } from 'react';
import { DialogForm } from '../../../components/DialogForm/DialogForm';
import { CustomersForm } from '../form/CustomersForm';
import { CustomersContext } from '../CustomersContext/CustomersContext';
import { CustomersTypesEnum } from '../CustomersContext/CustomersInterface';

interface CustomersFormDialogInterface {}

const CustomersFormDialog: React.FC<CustomersFormDialogInterface> = () => {
  const { stateCustomers, dispatchCustomers } = useContext(CustomersContext);

  const closeModal = () =>
    dispatchCustomers({
      type: CustomersTypesEnum.OPEN_CUSTOMERS_FORM,
      form: { open: false }
    });

  return (
    <>
      <DialogForm
        onClose={closeModal}
        open={stateCustomers.form.open}
        title="Clientes"
      >
        <CustomersForm editCustomers={stateCustomers.form.editCustomer} />
      </DialogForm>
    </>
  );
};

export default CustomersFormDialog;
