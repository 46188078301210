import {
  ProposalsContextInterface,
  ProposalsTypesEnum
} from '../ProposalsInterface';

export type UPDATE_STATE = {
  type: ProposalsTypesEnum.UPDATE_STATE;
  newState: Partial<ProposalsContextInterface>;
};

export function updateStateAction(
  state: ProposalsContextInterface,
  action: UPDATE_STATE
): ProposalsContextInterface {
  return {
    ...state,
    ...action.newState
  };
}
