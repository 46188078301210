import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

const CPFType = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={cpfMask}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
};

CPFType.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default CPFType;
