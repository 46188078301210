/* eslint-disable @typescript-eslint/no-use-before-define */
// Generated by https://quicktype.io
//
// To change quicktype's target language, run command:
//
//   "Set quicktype target language"

import { SimulateType } from './SimulateInterface';

export enum ProposalStatusLabelEnum {
  Declined = 'Proposta Recusada',
  Approved = 'Proposta Aprovada',
  Pending = 'Pendente'
}

export interface StatusProposal {
  uid: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  proposal_uid: string;
  StatusProposalType: StatusProposalType;
}

export interface StatusProposalType {
  uid: string;
  slug: string;
  label: ProposalStatusLabelEnum;
  createdAt: string;
  updatedAt: string;
}

export interface ProposalInterface {
  uid: string;
  amount: number;
  simulation: SimulateType;
  id_hubspot: string;
  createdAt: string;
  updatedAt: string;
  customer_uid: string;
  StatusProposals: StatusProposal[] | [];
  Customer: ProposalCustomerInterface;
  statusLabel?: ProposalStatusLabelEnum;
}

export interface ProposalCustomerInterface {
  uid: string;
  vat_number: string;
  company_name: string;
  fantasy_name: string;
  email: string;
  contact: string;
  phone: string;
  id_hubspot: string;
  createdAt: string;
  updatedAt: string;
  users_uid: string;
  User: UserInterface;
}

export interface UserInterface {
  uid: string;
  name: string;
  email: string;
  phone: string;
  role: string;
  createdAt: string;
  updatedAt: string;
}

export interface SimulationInterface {
  pf: Pf;
  pj: Pj;
}

export interface Pf {
  guarantee: PfGuarantee;
  nonGuarantee: PfNonGuarantee;
}

export interface PfGuarantee {
  vehicle: Vehicle;
  immobile: Immobile;
}

export interface Immobile {
  '30-4000': The150;
}

export interface The150 {
  prazo: number;
  taxa: number;
  pmt: string;
}

export interface Vehicle {
  '10-200': The150;
}

export interface PfNonGuarantee {
  '1-50': The150;
}

export interface Pj {
  guarantee: PjGuarantee;
  nonGuarantee: PjNonGuarantee;
}

export interface PjGuarantee {
  vehicle: Vehicle;
  immobile: Immobile;
  receivables: Receivables;
}

export interface Receivables {
  '10-1000': The150;
}

export interface PjNonGuarantee {
  '10-500': The150;
}
