import { SimulateInterface } from '../../interfaces/SimulateInterface';
import {
  ProposalsContextInterface,
  ProposalsTypesEnum
} from '../ProposalsInterface';

export type UPDATE_SIMULATION = {
  type: ProposalsTypesEnum.UPDATE_SIMULATION;
  simulation: SimulateInterface | undefined;
};

export function updateSimulationAction(
  state: ProposalsContextInterface,
  action: UPDATE_SIMULATION
): ProposalsContextInterface {
  return {
    ...state,
    createProposal: {
      ...state.createProposal,
      simulation: action.simulation
    }
  };
}
