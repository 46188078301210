import React from 'react';
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableProps
} from 'mui-datatables';
import { MUI_DATABLE_DEFAULT_OPTIONS } from './MUITSDatatableConfig';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface MUITSDatatableInterface<T> extends MUIDataTableProps {
  options: Partial<MUIDataTableOptions>;
}

const MUITSDatatable: <DATA>(
  props: MUITSDatatableInterface<DATA>
) => React.ReactElement = ({ title, data, columns, options, components }) => {
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={{
        ...MUI_DATABLE_DEFAULT_OPTIONS,
        ...options
      }}
      components={components}
    />
  );
};

export default MUITSDatatable;
