import {
  axiosPOST,
  CustomAxiosResponse,
  Token,
  VariantEnum
} from './../../../services/axiosService';
import { SimulateInterface } from '../interfaces/SimulateInterface';

export const simulate = async (
  amount: number,
  token: Token
): Promise<CustomAxiosResponse<SimulateInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/proposal/simulate`;

    const response = await axiosPOST<SimulateInterface>(
      endpoint,
      { amount },
      token
    );

    return {
      ...response,
      data: response.data,
      // message: `Fornecedor ${providerToBeCreated.fantasy_name} criado!`,
      variant: VariantEnum.Success
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    );
  }
};
