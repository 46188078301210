/* eslint-disable @typescript-eslint/no-use-before-define */
import { MUIDataTableProps } from 'mui-datatables';

export interface MUIColumnOptionsInterface {
  filter: boolean;
  sort: boolean;
}

export enum MUIResponsiveEnum {
  Stacked = 'stacked',
  Vertical = 'vertical',
  Standard = 'standard',
  Simple = 'simple'
}

export enum MUIFilterTypeEnum {
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
  Multiselect = 'multiselect',
  TextField = 'textField',
  Custom = 'custom'
}

export interface MUITranslationInterface {
  body: MUITranslationBodyInterface;
  pagination: MUITranslationPaginationInterface;
  toolbar: MUITranslationToolbarInterface;
  filter: MUITranslationFilterInterface;
  viewColumns: MUITranslationViewColumnsInterface;
  selectedRows: MUITranslationSelectedRowsInterface;
}

interface MUITranslationBodyInterface {
  noMatch: string;
  toolTip: string;
}

interface MUITranslationFilterInterface {
  all: string;
  title: string;
  reset: string;
}

interface MUITranslationPaginationInterface {
  next: string;
  previous: string;
  rowsPerPage: string;
  displayRows: string;
  jumpToPage: string;
}

interface MUITranslationSelectedRowsInterface {
  text: string;
  delete: string;
  deleteAria: string;
}

interface MUITranslationToolbarInterface {
  search: string;
  downloadCsv: string;
  print: string;
  viewColumns: string;
  filterTable: string;
}

interface MUITranslationViewColumnsInterface {
  title: string;
  titleAria: string;
}

export interface CustomMUIDataTableProps
  extends Omit<MUIDataTableProps, 'columns' | 'data' | 'options' | 'title'> {}

export enum HTMLElementInputTypeEnum {
  Text = 'text',
  Color = 'color',
  Checkbox = 'checkbox',
  Date = 'date',
  Datetime = 'datetime-local',
  Email = 'email',
  Number = 'number',
  Password = 'password',
  Range = 'range',
  Phone = 'phone',
  Url = 'url'
}
