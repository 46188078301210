import { PATH_ADMIN } from './paths';
import { lazy } from 'react';
import { AdminLayout } from 'src/layouts/DashboardLayout';
import { Routes } from '.';
import AdminProtect from 'src/components/Auth/AdminProtect';

// ----------------------------------------------------------------------
const AdminRoutes: Routes = {
  path: PATH_ADMIN.root,
  guard: [AdminProtect],
  layout: AdminLayout,
  routes: [
    {
      exact: true,
      path: PATH_ADMIN.root,
      component: lazy(() => import('src/views/admin/AdminPage'))
    },
    {
      exact: true,
      path: PATH_ADMIN.clients,
      component: lazy(() => import('src/views/users/UsersRootPage'))
    }
  ]
};

export default AdminRoutes;
