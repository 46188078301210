// @ts-nocheck
import React from 'react';
import Slider from '@material-ui/core/Slider';

const zip = (...arrays) =>
  [...new Array(Math.max(...arrays.map((a) => a.length)))]
    .map((_, i) => i)
    .map((i) => arrays.map((a) => a[i]));

const createRange = ([fromMin, fromMax], [toMin, toMax]) => {
  const fromRange = fromMax - fromMin;
  const toRange = toMax - toMin;
  return (fromValue) => ((fromValue - fromMin) / fromRange) * toRange + toMin;
};

const createScale = (min, max, marks) => {
  const zippedMarks = zip([undefined].concat(marks), marks);
  const zone = (max - min) / (marks.length - 1);
  const zones = marks.map((_, i) => [i * zone + min, (i + 1) * zone + min]);
  const ranges = zippedMarks
    .filter(([a, b]) => a !== undefined && b !== undefined)
    .map(([a, b], i) => [
      createRange(zones[i], [a.value, b.value]),
      zones[i],
      createRange([a.value, b.value], zones[i]),
      [a.value, b.value]
    ]);
  const sliderValToScaled = (sliderVal) =>
    ranges.find(([, [low, high]]) => sliderVal >= low && sliderVal <= high)[0](
      sliderVal
    );
  const scaledValToSlider = (scaledVal) => {
    try {
      return ranges.find(
        ([, , , [low, high]]) => scaledVal >= low && scaledVal <= high
      )[2](scaledVal);
    } catch (error) {
      return scaledVal;
    }
  };

  return [sliderValToScaled, scaledValToSlider];
};

export default function AmountField({
  marks = [
    { value: 0, label: '0' },
    { value: 1, label: '1' }
  ],
  steps = 200,
  onChange = (x) => x,
  value = 0,
  numFormatter,
  disabled = false
}) {
  const handleChange = (event, newValue) => {
    onChange(scale(newValue));
  };

  const [scale, unscale] = React.useMemo(() => createScale(0, 1, marks), [
    marks
  ]);

  const followersMarks = marks
    .filter((mark) => mark.label)
    .map((mark) => ({
      ...mark,
      value: unscale(mark.value)
    }));

  return (
    <Slider
      value={unscale(value)}
      min={0}
      step={1 / steps}
      max={1}
      valueLabelFormat={numFormatter}
      marks={followersMarks}
      scale={scale}
      onChange={handleChange}
      valueLabelDisplay="off"
      disabled={disabled}
      aria-labelledby="non-linear-slider"
    />
  );
}
