import React from 'react';

import useAuth from 'src/hooks/useAuth';

import { MAvatar } from 'src/theme';
import createAvatar from 'src/utils/createAvatar';

type Props = {
  className?: string;
};

function MyAvatar({ className, ...other }: Props) {
  const { user } = useAuth();

  return (
    // @ts-ignore ts-migrate(2322) FIXME: Type '{ children: string; src: any; alt: any; colo... Remove this comment to see the full error message
    <MAvatar
      src={user?.name}
      alt={user?.name}
      // @ts-ignore
      color={user?.photoURL ? 'default' : createAvatar(user?.name).color}
      className={className}
      {...other}
    >
      {createAvatar(user?.name).name}
    </MAvatar>
  );
}

export default MyAvatar;
