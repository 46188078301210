import {
  UsersAction,
  UsersContextInterface,
  UsersTypesEnum
} from './UsersInterface';
import { updateStateAction } from './actions/updateStateAction';
import { addUserToStateAction } from './actions/addUserToStateAction';
import { updateUsersListAction } from './actions/updateUsersListAction';
import { openUsersFormAction } from './actions/openUsersFormAction';
import { updateSingleUserAction } from './actions/updateSingleUserAction';

function updateStateReducer(
  state: UsersContextInterface,
  action: UsersAction
): UsersContextInterface {
  switch (action.type) {
    case UsersTypesEnum.UPDATE_STATE:
      return updateStateAction(state, action);

    case UsersTypesEnum.ADD_CUSTOMER_TO_STATE:
      return addUserToStateAction(state, action);

    case UsersTypesEnum.UPDATE_CUSTOMERS_LIST:
      return updateUsersListAction(state, action);

    case UsersTypesEnum.OPEN_CUSTOMERS_FORM:
      return openUsersFormAction(state, action);

    case UsersTypesEnum.UPDATE_SINGLE_CUSTOMER:
      return updateSingleUserAction(state, action);

    default:
      return state;
  }
}

export default updateStateReducer;
