import React, { createContext, useEffect } from 'react';
import Reducer, { AuthAction } from './AuthReducer';
import { authInitialState } from './AuthInitializer';
import { AuthContextInterface, FirebaseAuth } from './AuthInterface';
import firebase from 'firebase';
import useLocalStorageReducer from 'src/hooks/useLocalStorageReducer';

export type AuthDispatchType = React.Dispatch<AuthAction>;

interface AuthProviderInterface {
  stateAuth: AuthContextInterface;
  dispatchAuth: AuthDispatchType;
  firebaseAuth: FirebaseAuth;
}

interface AuthProviderMockInterface {
  injectMock?: Partial<AuthContextInterface>;
}

export const AuthContext = createContext<AuthProviderInterface>({
  stateAuth: authInitialState,
  dispatchAuth: () => console.warn('AuthDispatch not ready'),
  firebaseAuth: undefined
});

const AuthProvider: React.FC<AuthProviderMockInterface> = ({
  children,
  injectMock
}) => {
  // const [stateAuth, dispatchAuth] = useReducer(Reducer, authInitialState);
  const [stateAuth, dispatchAuth] = useLocalStorageReducer(
    'loggedUser222',
    Reducer,
    authInitialState,
    60 * 60 * 23
  );

  const firebaseAuth = firebase.auth();

  useEffect(() => {
    // firebase.auth().onAuthStateChanged((user) => {
    //   dispatchAuth({
    //     type: AuthTypesEnum.SET_FIREBASE_USER,
    //     firebaseUser: user
    //   });
    //   if (user === null)
    //     dispatchAuth({
    //       type: AuthTypesEnum.LOGOUT_USER
    //     });
    // });
  }, [dispatchAuth]);

  const globals = {
    stateAuth,
    dispatchAuth,
    firebaseAuth,
    ...injectMock
  };

  return (
    <AuthContext.Provider value={globals}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
