// @ts-nocheck
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { Button } from '@material-ui/core';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
const useStyles = makeStyles((theme) => {
  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const styleContained = (color) => {
    // @ts-ignore ts-migrate(2493) FIXME: Tuple type 'Shadows' of length '25' has no element... Remove this comment to see the full error message
    return {
      boxShadow: theme.shadows[25][color],
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

      color: theme.palette[color].contrastText,

      backgroundColor: theme.palette[color].main,

      '&:hover': {
        backgroundColor: theme.palette[color].dark
      }
      // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
    };
    // @ts-ignore ts-migrate(2493) FIXME: Tuple type 'Shadows' of length '25' has no element... Remove this comment to see the full error message
  };

  const styleOutlined = (color) => {
    return {
      color: theme.palette[color].main,
      border: `1px solid ${alpha(theme.palette[color].main, 0.48)}`,

      '&:hover': {
        // @ts-ignore ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ childre... Remove this comment to see the full error message
        border: `1px solid ${theme.palette[color].main}`,
        // @ts-ignore ts-migrate(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message

        // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
        backgroundColor: alpha(
          theme.palette[color].main,
          theme.palette.action.hoverOpacity
        )
        // @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
      }
    };
  };

  // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.

  const styleText = (color) => {
    return {
      color: theme.palette[color].main,
      '&:hover': {
        backgroundColor: alpha(
          theme.palette[color].main,
          theme.palette.action.hoverOpacity
        )
      }
    };
  };
  return {
    containedInfo: styleContained('info'),
    containedSuccess: styleContained('success'),
    containedWarning: styleContained('warning'),
    containedError: styleContained('error'),
    // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    containedWhite: {
      boxShadow: theme.shadows[25].z8,
      color: theme.palette.getContrastText(theme.palette.common.white),
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },

    outlinedInfo: styleOutlined('info'),
    outlinedSuccess: styleOutlined('success'),
    outlinedWarning: styleOutlined('warning'),
    outlinedError: styleOutlined('error'),

    textInfo: styleText('info'),
    textSuccess: styleText('success'),
    textWarning: styleText('warning'),
    textError: styleText('error')
  };
});

// ----------------------------------------------------------------------

const MButton = forwardRef(
  (
    { color = 'primary', variant = 'text', children, className, ...other },
    ref
  ) => {
    const classes = useStyles();

    if (color === 'inherit' || color === 'primary' || color === 'secondary') {
      return (
        <Button
          ref={ref}
          color={color}
          variant={variant}
          className={className}
          {...other}
        >
          {children}
        </Button>
      );
    }

    return (
      <Button
        ref={ref}
        variant={variant}
        className={clsx(
          // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
          classes[variant],
          {
            [classes[`${variant}${capitalize(color)}`]]: color
          },
          className
        )}
        {...other}
      >
        {/* @ts-ignore ts-migrate(2769) FIXME: No overload matches this call. */}
        {children}
      </Button>
    );
  }
);

export default MButton;
