import { ProposalInterface } from './../../interfaces/ProposalInterface';
import {
  ProposalsContextInterface,
  ProposalsTypesEnum
} from '../ProposalsInterface';

export type UPDATE_SINGLE_PROPOSAL = {
  type: ProposalsTypesEnum.UPDATE_SINGLE_PROPOSAL;
  proposal: ProposalInterface;
};

export function updateSingleProposalAction(
  state: ProposalsContextInterface,
  action: UPDATE_SINGLE_PROPOSAL
): ProposalsContextInterface {
  return {
    ...state,
    proposals: state.proposals.map((unitProposal) =>
      unitProposal.uid === action.proposal.uid ? action.proposal : unitProposal
    )
  };
}
