import { Box, TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  UseControllerOptions
} from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface TextfieldControllerInterface<T> extends UseControllerOptions {
  control: Control<Record<string, any>>;
  errors: DeepMap<any, FieldError>;
  variant?: 'filled' | 'outlined';
  placeholder?: string;
  textfieldProps?: TextFieldProps;
}

export interface GenericControllerTextfieldInterface<T>
  extends Omit<
    TextfieldControllerInterface<T>,
    'variant' | 'placeholder' | 'textfieldProps' | 'name' | 'onFocus' | 'rules'
  > {}

export const TextfieldController: <T>(
  props: TextfieldControllerInterface<T>
) => React.ReactElement = ({
  control,
  errors,
  name,
  defaultValue,
  onFocus,
  rules,
  variant = 'outlined',
  placeholder,
  textfieldProps,
  ...props
}) => {
  const hasError = errors.hasOwnProperty(name);
  const message = errors && errors[name]?.message;

  return (
    <Box mb={2}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        onFocus={onFocus}
        defaultValue={defaultValue}
        render={({ onChange, ...params }) => (
          <TextField
            {...props}
            {...params}
            id={`field-${name}`}
            placeholder={placeholder}
            name={name}
            variant={variant}
            fullWidth
            error={hasError}
            helperText={message}
            onChange={onChange}
            {...textfieldProps}
          />
        )}
      />
    </Box>
  );
};

export default TextfieldController;
