// @ts-nocheck
import clsx from 'clsx';

import React, { forwardRef } from 'react';
import { ButtonAnimate } from 'src/components/Animate';
import { makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { Fab } from '@material-ui/core';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
const useStyles = makeStyles((theme) => {
  // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const createStyle = (color) => {
    // @ts-ignore ts-migrate(2493) FIXME: Tuple type 'Shadows' of length '25' has no element... Remove this comment to see the full error message
    return {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ childre... Remove this comment to see the full error message
      boxShadow: theme.shadows[25][color],
      // @ts-ignore ts-migrate(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message

      color: theme.palette[color].contrastText,
      backgroundColor: theme.palette[color].main,
      // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.

      '&:hover': {
        // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
        backgroundColor: theme.palette[color].dark
      }
    };
  };

  return {
    colorInfo: createStyle('info'),
    colorSuccess: createStyle('success'),
    colorWarning: createStyle('warning'),
    colorError: createStyle('error')
  };
});

// ----------------------------------------------------------------------

const MFab = forwardRef(
  ({ color = 'primary', children, className, ...other }, ref) => {
    const classes = useStyles();

    if (
      color === 'default' ||
      color === 'inherit' ||
      color === 'primary' ||
      color === 'secondary'
    ) {
      return (
        <ButtonAnimate>
          <Fab ref={ref} color={color} className={className} {...other}>
            {children}
          </Fab>
        </ButtonAnimate>
      );
    }

    return (
      <ButtonAnimate>
        <Fab
          ref={ref}
          className={clsx(
            {
              [classes[`color${capitalize(color)}`]]: color
            },

            className
          )}
          {...other}
        >
          {children}
        </Fab>
        {/* @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      </ButtonAnimate>
    );
  }
);

export default MFab;
