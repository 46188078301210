import { I18nInterface } from './i18n';

// ----------------------------------------------------------------------

export const codes = {
  // Email
  emailAlreadyinUse: {
    code: 'auth/email-already-in-use',
    text: 'There already exists an account with the given email address.'
  },
  invalidEmail: {
    code: 'auth/invalid-email',
    text: 'The email address is not valid.'
  },
  userDisabled: {
    code: 'auth/user-disabled',
    text: 'The user corresponding to the given credential has been disabled.'
  },
  userNotFound: {
    code: 'auth/user-not-found',
    text: 'There is no user corresponding to the email address.'
  },

  // Password
  wrongPassword: {
    code: 'auth/wrong-password',
    text: 'Wrong password'
  },
  weakPassword: {
    code: 'auth/weak-password',
    text: 'Password should be at least 6 characters'
  }
};

const {
  emailAlreadyinUse,
  invalidEmail,
  userDisabled,
  userNotFound,
  wrongPassword,
  weakPassword
} = codes;

export function emailError(errors: any, t: I18nInterface) {
  return {
    error:
      errors === emailAlreadyinUse.code ||
      errors === invalidEmail.code ||
      errors === userDisabled.code ||
      errors === userNotFound.code,
    helperText:
      (errors === emailAlreadyinUse.code &&
        t('login.form.errors.emailAlreadyinUse')) ||
      (errors === invalidEmail.code && t('login.form.errors.invalidEmail')) ||
      (errors === userDisabled.code && t('login.form.errors.userDisabled')) ||
      (errors === userNotFound.code && t('login.form.errors.userNotFound'))
  };
}

export function passwordError(errors: any, t: I18nInterface) {
  return {
    error: errors === wrongPassword.code || errors === weakPassword.code,
    helperText:
      (errors === wrongPassword.code && t('login.form.errors.wrongPassword')) ||
      (errors === weakPassword.code && t('login.form.errors.weakPassword'))
  };
}
