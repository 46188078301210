// @ts-nocheck
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { Checkbox } from '@material-ui/core';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

const useStyles = makeStyles((theme) => {
  const createStyle = (color) => {
    return {
      '&.Mui-checked': {
        color: theme.palette[color].main
      },

      '&.MuiCheckbox-indeterminate': {
        color: theme.palette[color].main
      },
      '&:hover, &.Mui-checked:hover': {
        // @ts-ignore ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ childre... Remove this comment to see the full error message

        backgroundColor: alpha(
          theme.palette[color].main,
          // @ts-ignore ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
          theme.palette.action.hoverOpacity
        )
      }
    };
  };

  return {
    // @ts-ignore ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
    colorInfo: createStyle('info'),
    colorSuccess: createStyle('success'),

    colorWarning: createStyle('warning'),
    colorError: createStyle('error')
  };
});

// ----------------------------------------------------------------------

const MCheckbox = forwardRef(
  ({ color = 'primary', className, ...other }, ref) => {
    const classes = useStyles();

    if (color === 'default' || color === 'primary' || color === 'secondary') {
      return (
        <Checkbox ref={ref} color={color} className={className} {...other} />
      );
    }

    return (
      <Checkbox
        ref={ref}
        className={clsx(
          {
            [classes[`color${capitalize(color)}`]]: color
          },
          className
        )}
        {...other}
      />
    );
  }
);

export default MCheckbox;
