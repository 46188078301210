import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { GenericControllerTextfieldInterface } from 'src/components/TextfieldController';

interface CpfCnpjControllerProps {}

export enum VatNumberCpfCnpjEnum {
  CNPJ = 'cnpj',
  CPF = 'cpf'
}

const CpfCnpjController: React.FC<
  GenericControllerTextfieldInterface<CpfCnpjControllerProps>
> = ({ control, errors, defaultValue }) => {
  return (
    <>
      <Controller
        control={control}
        name="cpfOrCnpj"
        errors={errors}
        render={(props) => (
          <FormControl component="fieldset" {...props}>
            <RadioGroup
              row
              // @ts-ignore
              defaultValue={defaultValue}
              onChange={(e) => {
                control.trigger();
                props.onChange(e.target.value);
                control.trigger();
              }}
            >
              <FormControlLabel
                value={VatNumberCpfCnpjEnum.CPF}
                control={<Radio />}
                label="Pessoa física"
              />
              <FormControlLabel
                value={VatNumberCpfCnpjEnum.CNPJ}
                control={<Radio />}
                label="Pessoa jurídica"
              />
            </RadioGroup>
          </FormControl>
        )}
      />
    </>
  );
};

export default CpfCnpjController;
