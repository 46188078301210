import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { Routes } from '.';
import ProposalsRootPage from 'src/views/proposals/ProposalsRootPage';
import CustomersPage from 'src/views/customers/CustomersPage';
import ProposalCreate from 'src/views/proposals/layouts/ProposalCreate';

// ----------------------------------------------------------------------
const AppRoutes: Routes = {
  path: PATH_APP.root,
  guard: [AuthProtect],
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => import('src/views/general/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('src/views/general/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.general.analytics,
      component: lazy(() => import('src/views/general/DashboardAnalyticsView'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />
    },
    {
      exact: true,
      path: PATH_APP.management.user.profile,
      component: lazy(() => import('src/views/user/ProfileView'))
    },
    {
      exact: true,
      path: PATH_APP.proposal.list,
      component: lazy(() => import('src/views/proposals/ProposalsPage'))
    },
    {
      exact: true,
      path: PATH_APP.proposal.create,
      layout: ProposalsRootPage,
      component: ProposalCreate
    },
    {
      exact: true,
      path: `${PATH_APP.proposal.view}/:uid`,
      layout: ProposalsRootPage,
      component: lazy(() => import('src/views/proposals/layouts/ProposalView'))
    },
    {
      exact: true,
      path: PATH_APP.customers.list,
      component: CustomersPage
    },
    {
      exact: true,
      path: PATH_APP.management.user.account,
      component: lazy(() => import('src/views/user/AccountView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />
    },
    {
      exact: true,
      path: PATH_APP.management.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
