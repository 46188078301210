// @ts-nocheck
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { capitalize } from '@material-ui/core/utils';
import { TimelineDot } from '@material-ui/lab';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  // Filled
  filledInfo: { backgroundColor: theme.palette.info.main },
  filledSuccess: { backgroundColor: theme.palette.success.main },
  filledWarning: { backgroundColor: theme.palette.warning.main },
  filledError: { backgroundColor: theme.palette.error.main },

  // Outlined
  outlinedInfo: { borderColor: theme.palette.info.main },
  outlinedSuccess: { borderColor: theme.palette.success.main },
  outlinedWarning: { borderColor: theme.palette.warning.main },
  outlinedError: { borderColor: theme.palette.error.main }
}));

type Props = {
  classes?: object;
  className?: string;
  color?:
    | 'grey'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
  variant?: 'filled' | 'outlined' | string;
};

// ----------------------------------------------------------------------

function MTimelineDot({
  color = 'grey',
  variant = 'filled',
  className,
  ...other
}: Props) {
  const classes = useStyles();

  if (
    color === 'grey' ||
    color === 'inherit' ||
    color === 'primary' ||
    color === 'secondary'
  ) {
    return (
      <TimelineDot
        color={color}
        // @ts-ignore ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"filled" ... Remove this comment to see the full error message
        variant={variant}
        className={className}
        {...other}
      />
    );
  }

  return (
    <TimelineDot
      // @ts-ignore ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"filled" ... Remove this comment to see the full error message
      variant={variant}
      className={clsx(
        // @ts-ignore ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"filled" ... Remove this comment to see the full error message
        classes[variant],
        {
          [classes[`${variant}${capitalize(color)}`]]: color
        },

        className
        // @ts-ignore ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"filled" ... Remove this comment to see the full error message
      )}
      {...other}
    />
  );
}

export default MTimelineDot;
