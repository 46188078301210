/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import 'firebase/remote-config';
import {
  FirebaseRemoteConfigProviderInterface,
  RemoteConfigInterface
} from './FirebaseRemoteConfigInterfaces';
import { remoteConfigInitialState } from './FirebaseRemoteConfigInit';

export const FirebaseRemoteConfigContext = React.createContext<RemoteConfigInterface>(
  remoteConfigInitialState
);

const FirebaseRemoteConfigProvider: React.FC<FirebaseRemoteConfigProviderInterface> = ({
  children,
  remoteConfig
}) => {
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 10000,
    fetchTimeoutMillis: 60000
  };

  remoteConfig.defaultConfig = {};

  useEffect(() => {
    function setValues(): void {
      // setMenuItems({});
    }

    function fetchConfig(): void {
      remoteConfig
        .fetchAndActivate()
        .then(() => setValues())
        .catch((err) => console.error(err));
    }

    remoteConfig
      .ensureInitialized()
      .then(() => fetchConfig())
      .catch((err) =>
        console.error('Firebase Remote Config failed to initialize', err)
      );
  }, [remoteConfig]);

  const globals = {};

  return (
    <FirebaseRemoteConfigContext.Provider value={globals}>
      {children}
    </FirebaseRemoteConfigContext.Provider>
  );
};

export default FirebaseRemoteConfigProvider;
