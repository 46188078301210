// @ts-nocheck
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Avatar } from '@material-ui/core';
import { capitalize } from '@material-ui/core/utils';
import { makeStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'color' implicitly has an 'any' type.

const useStyles = makeStyles((theme) => {
  const createStyle = (color) => {
    return {
      color: `${theme.palette[color].contrastText} !important`,
      backgroundColor: `${theme.palette[color].main} !important`
    };
  };

  return {
    root: {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'color' does not exist on type '{ childre... Remove this comment to see the full error message
      fontWeight: theme.typography.fontWeightMedium
      // @ts-ignore ts-migrate(2339) FIXME: Property 'className' does not exist on type '{ chi... Remove this comment to see the full error message
    },
    colorPrimary: createStyle('primary'),
    // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
    colorSecondary: createStyle('secondary'),
    colorInfo: createStyle('info'),
    colorSuccess: createStyle('success'),

    colorWarning: createStyle('warning'),
    colorError: createStyle('error')
  };
});

// ----------------------------------------------------------------------

const MAvatar = forwardRef(
  ({ color = 'default', children, className, ...other }, ref) => {
    const classes = useStyles();

    return (
      <Avatar
        ref={ref}
        className={clsx(
          classes.root,
          {
            [classes[`color${capitalize(color)}`]]: color
          },

          className
        )}
        {...other}
      >
        {children}
      </Avatar>
    );
  }
);

export default MAvatar;
