import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { switchMode, switchDirection } from 'src/redux/slices/settings';

// ----------------------------------------------------------------------

function useSettings() {
  const dispatch = useDispatch();
  // @ts-ignore ts-migrate(2339) FIXME: Property 'settings' does not exist on type 'Defaul... Remove this comment to see the full error message
  const { themeMode, themeDirection } = useSelector((state) => state.settings);

  const isLight = themeMode === 'light';

  const handleToggleTheme = useCallback(() => {
    return dispatch(switchMode(isLight ? 'dark' : 'light'));
  }, [dispatch, isLight]);

  const handleChangeTheme = useCallback(
    (event) => {
      try {
        return dispatch(switchMode(event.target.value));
      } catch (error) {
        return dispatch(switchMode('dark'));
      }
    },
    [dispatch]
  );

  const handleChangeDirection = useCallback(
    (event) => dispatch(switchDirection(event.target.value)),
    [dispatch]
  );

  return {
    // Mode
    themeMode: themeMode,
    toggleMode: handleToggleTheme,
    selectMode: handleChangeTheme,
    // Direction
    themeDirection: themeDirection,
    selectDirection: handleChangeDirection
  };
}

export default useSettings;
