import { DialogFormInterface } from './actions/openDialogAction';

export interface LayoutContextDialogInterface extends DialogFormInterface {
  isOpen: boolean;
}

export interface LayoutContextInterface {
  title: string;
  dialog: LayoutContextDialogInterface;
}

export enum LayoutTypesEnum {
  CONFIGURE_PAGE,
  OPEN_DIALOG,
  CLOSE_DIALOG
}
