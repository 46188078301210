import {
  CustomersAction,
  CustomersContextInterface,
  CustomersTypesEnum
} from './CustomersInterface';
import { updateStateAction } from './actions/updateStateAction';
import { addCustomerToStateAction } from './actions/addCustomerToStateAction';
import { updateCustomersListAction } from './actions/updateCustomersListAction';
import { openCustomersFormAction } from './actions/openCustomersFormAction';
import { updateSingleCustomerAction } from './actions/updateSingleCustomerAction';

function updateStateReducer(
  state: CustomersContextInterface,
  action: CustomersAction
): CustomersContextInterface {
  switch (action.type) {
    case CustomersTypesEnum.UPDATE_STATE:
      return updateStateAction(state, action);

    case CustomersTypesEnum.ADD_CUSTOMER_TO_STATE:
      return addCustomerToStateAction(state, action);

    case CustomersTypesEnum.UPDATE_CUSTOMERS_LIST:
      return updateCustomersListAction(state, action);

    case CustomersTypesEnum.OPEN_CUSTOMERS_FORM:
      return openCustomersFormAction(state, action);

    case CustomersTypesEnum.UPDATE_SINGLE_CUSTOMER:
      return updateSingleCustomerAction(state, action);

    default:
      return state;
  }
}

export default updateStateReducer;
