import { CustomerInterface } from '../../interfaces/CustomerInterface';
import {
  CustomersContextInterface,
  CustomersTypesEnum
} from '../CustomersInterface';

export type UPDATE_CUSTOMERS_LIST = {
  type: CustomersTypesEnum.UPDATE_CUSTOMERS_LIST;
  customers: CustomerInterface[];
};

export function updateCustomersListAction(
  state: CustomersContextInterface,
  action: UPDATE_CUSTOMERS_LIST
): CustomersContextInterface {
  return {
    ...state,
    customers: action.customers
  };
}
