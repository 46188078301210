// import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
// import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
// import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';

import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  EditableCell,
  ExtendableCellInterface
} from '../../../../components/MUITSDatatable';
import { HTMLElementInputTypeEnum } from '../../../../components/MUITSDatatable/MUITSDatatableInterface';
import TextfieldController, {
  GenericControllerTextfieldInterface
} from '../../../../components/TextfieldController';
import { CustomersContext } from '../../CustomersContext/CustomersContext';
import { updateCustomer } from '../../services/updateCustomer';
import { VatNumberCpfCnpjEnum } from './CpfCnpjController';
import { validateEmail } from './validators/Validators';

interface EmailInterface extends ExtendableCellInterface {}

export const EmailTextfield: React.FC<
  GenericControllerTextfieldInterface<EmailInterface>
> = ({ control, errors }) => {
  const isPJ = control.getValues('cpfOrCnpj') === VatNumberCpfCnpjEnum.CNPJ;

  useEffect(() => {
    control.trigger();
  }, [isPJ, control]);

  return (
    <>
      <TextfieldController
        control={control}
        errors={errors}
        name="email"
        placeholder="fulano@gmail.com"
        rules={{
          required: isPJ ? 'Este campo é obrigatório!' : false,
          validate: {
            email: (str: string) => {
              if (str === '' || !str) return true;

              return validateEmail(str) ? true : 'Email inválido';
            }
          }
        }}
        textfieldProps={{ label: 'Email' }}
      />
    </>
  );
};

export const Email: React.FC<EmailInterface> = ({
  tableMeta,
  updateValue,
  value
}): JSX.Element => {
  const { stateCustomers } = useContext(CustomersContext);
  const [cookies] = useCookies(['authToken']);
  const id = tableMeta.rowData[0];

  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    updateCustomer(id, { email: valueToUpdate }, cookies.authToken);
  };

  return (
    <EditableCell
      type={HTMLElementInputTypeEnum.Text}
      value={stateCustomers?.customers[tableMeta.rowIndex]?.email}
      tableMeta={tableMeta}
      updateValue={updateValue}
      disabled={true}
      handleUpdate={handleUpdate}
      rules={{
        required: 'Descrição obrigatório'
      }}
    />
  );
};

export default Email;
