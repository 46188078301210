import { LayoutContextInterface, LayoutTypesEnum } from '../LayoutInterface';

export interface DialogFormInterface {
  content: JSX.Element;
  title: string;
}

export type OPEN_DIALOG = {
  type: LayoutTypesEnum.OPEN_DIALOG;
  dialog: DialogFormInterface;
};

export function openDialogAction(
  state: LayoutContextInterface,
  action: OPEN_DIALOG
): LayoutContextInterface {
  return {
    ...state,
    dialog: {
      ...action.dialog,
      isOpen: true
    }
  };
}
