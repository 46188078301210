import {
  ProposalInterface,
  ProposalStatusLabelEnum
} from './../../interfaces/ProposalInterface';
import {
  ProposalsContextInterface,
  ProposalsTypesEnum
} from '../ProposalsInterface';

export type UPDATE_PROPOSALS_LIST = {
  type: ProposalsTypesEnum.UPDATE_PROPOSALS_LIST;
  proposals: ProposalInterface[];
};

export function addStatusLabel(
  proposals: ProposalInterface[]
): ProposalInterface[] {
  return proposals.map((proposal) => {
    return {
      ...proposal,
      statusLabel: proposal.StatusProposals[0]
        ? proposal.StatusProposals[0].StatusProposalType.label
        : ProposalStatusLabelEnum.Pending
    };
  });
}

export function updateProposalsListAction(
  state: ProposalsContextInterface,
  action: UPDATE_PROPOSALS_LIST
): ProposalsContextInterface {
  return {
    ...state,
    proposals: addStatusLabel(action.proposals)
  };
}
