import { ADD_SWR_PROPS } from './actions/addSwrPropsAction';
import { UPDATE_SIMULATION_LOADING } from './actions/updateSimulationLoadingAction';
import { UPDATE_SIMULATION } from './actions/updateSimulationAction';
import { SimulateInterface } from './../interfaces/SimulateInterface';
import { CustomerInterface } from './../../customers/interfaces/CustomerInterface';
import { UPDATE_AMOUNT } from './actions/updateAmountAction';
import { ProposalInterface } from './../interfaces/ProposalInterface';
import { UPDATE_PROPOSALS_LIST } from './actions/updateProposalsListAction';
import { UPDATE_SELECTED_COSTUMER } from './actions/updateSelectedCostumerAction';
import { UPDATE_STATE } from './actions/updateStateAction';
import { UPDATE_SINGLE_PROPOSAL } from './actions/updateSingleProposalAction';

export interface ProposalsContextInterface {
  proposals: ProposalInterface[];
  revalidate?: () => Promise<boolean>;
  createProposal: {
    selectedCostumer: CustomerInterface | undefined | null;
    amount: number | undefined;
    isAmountValid: boolean;
    simulation: SimulateInterface | undefined;
    isLoadingSimulation: boolean;
  };
}

export type ProposalsAction =
  | UPDATE_STATE
  | UPDATE_PROPOSALS_LIST
  | UPDATE_SELECTED_COSTUMER
  | UPDATE_SIMULATION
  | UPDATE_SIMULATION_LOADING
  | UPDATE_SINGLE_PROPOSAL
  | ADD_SWR_PROPS
  | UPDATE_AMOUNT;

export enum ProposalsTypesEnum {
  UPDATE_STATE,
  UPDATE_PROPOSALS_LIST,
  UPDATE_SELECTED_COSTUMER,
  UPDATE_SIMULATION,
  UPDATE_SIMULATION_LOADING,
  UPDATE_SINGLE_PROPOSAL,
  ADD_SWR_PROPS,
  UPDATE_AMOUNT
}
