import rtl from 'jss-rtl';
import { create } from 'jss';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import breakpoints from './breakpoints';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import GlobalStyles from './globalStyles';
import borderRadius from './borderRadius';
import componentsOverride from './overrides';

import { CacheProvider } from '@emotion/react';
import useSettings from 'src/hooks/useSettings';
import React, { useMemo, useEffect } from 'react';
import {
  jssPreset,
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
  Theme,
  createGenerateClassName
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Shape } from '@material-ui/core/styles/shape';

type RTLProviderProps = {
  direction?: 'ltr' | 'rtl';
  children?: React.ReactNode;
};

const generateClassName = createGenerateClassName({
  productionPrefix: 'ock-',
  seed: 'ockgenerator'
});

function RTLProvider({ direction, children }: RTLProviderProps) {
  const isRTL = direction === 'rtl';
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()]
  });

  const cache = createCache({
    key: isRTL ? 'rtl' : 'css',
    prepend: true,
    // @ts-ignore ts-migrate(2322) FIXME: Type '((context: number, content: string) => strin... Remove this comment to see the full error message
    stylisPlugins: isRTL ? [rtlPlugin] : []
  });

  cache.compat = true;

  useEffect(() => {
    // @ts-ignore ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    if (document) document.dir = direction;
  }, [direction]);

  return (
    <CacheProvider value={cache}>
      <StylesProvider generateClassName={generateClassName} jss={jss}>
        {children}
      </StylesProvider>
    </CacheProvider>
  );
}

type ThemeConfigProps = {
  children?: React.ReactNode;
};

interface CustomShape extends Shape {
  borderRadius: number;
  borderRadiusSm: number;
  borderRadiusMd: number;
}

export interface CustomTheme extends Omit<Theme, 'shape'> {
  shape: CustomShape;
}

function ThemeConfig({ children }: ThemeConfigProps) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: palette[isLight ? 'light' : 'dark'],
      shadows: shadows[isLight ? 'light' : 'dark'],
      typography: typography,
      shape: borderRadius,
      breakpoints: breakpoints,
      direction: themeDirection,
      components: componentsOverride({
        theme: {
          palette: palette[isLight ? 'light' : 'dark'],
          shadows: shadows[isLight ? 'light' : 'dark'],
          typography: typography,
          shape: borderRadius,
          direction: themeDirection
        }
      })
    }),

    [isLight, themeDirection]
  );

  // @ts-ignore ts-migrate(2322) FIXME: Type 'Theme' is not assignable to type 'CustomThem... Remove this comment to see the full error message
  // @ts-ignore
  // @ts-ignore ts-migrate(2345) FIXME: Argument of type '{ palette: { mode: string; text:... Remove this comment to see the full error message
  const theme: CustomTheme = createMuiTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <RTLProvider direction={themeDirection}>{children}</RTLProvider>
    </ThemeProvider>
  );
}

export default ThemeConfig;
