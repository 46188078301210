import { OckUserInterface } from './../../auth/interfaces/UserInterface';
import {
  axiosGET,
  CustomAxiosResponse,
  Token,
  VariantEnum
} from '../../../services/axiosService';

export const listUsers = async (
  token: Token
): Promise<CustomAxiosResponse<OckUserInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND}/user/all`;

    const response = await axiosGET<OckUserInterface[]>(endpoint, token);

    return {
      ...response,
      data: response.data,
      message: 'Pronto!',
      variant: VariantEnum.Success
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.'
    );
  }
};
