import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FeedbackContext } from '../../../contexts/FeedbackContext/FeedbackContext';
import { FeedbackTypesEnum } from '../../../contexts/FeedbackContext/FeedbackInterface';
import { VariantEnum } from '../../../services/axiosService';
import { ProposalInterface } from '../interfaces/ProposalInterface';
import { motion } from 'framer-motion';
import { CreateProposalInteface } from '../services/createProposal';
import { SelectClientField } from './fields/SelectClientField';
import SelectAmountField from './fields/SelectAmountField';
import CurrencyInput from '../components/CurrencyInput';
import { ProposalsContext } from '../ProposalsContext/ProposalsContext';
import { ProposalsTypesEnum } from '../ProposalsContext/ProposalsInterface';
import { MotionContainer, varBounceIn } from 'src/components/Animate';
import Add from '@material-ui/icons/Add';
import { CustomersContext } from 'src/views/customers/CustomersContext/CustomersContext';
import { CustomersTypesEnum } from 'src/views/customers/CustomersContext/CustomersInterface';
// import { AddressTextfield } from './fields/Address';

interface ProvidersFormInterface {
  editProviders?: Partial<ProposalInterface>;
}

export interface ProvidersFormData extends CreateProposalInteface {}

export const ProposalsForm: React.FC<ProvidersFormInterface> = ({
  editProviders
}) => {
  const { dispatchCustomers } = useContext(CustomersContext);
  const { stateProposals, dispatchProposals } = useContext(ProposalsContext);
  const { dispatchFeedback } = useContext(FeedbackContext);
  // const { dispatchProposals } = useContext(ProposalsContext);
  const {
    handleSubmit,
    errors,
    control,
    setValue
  } = useForm<ProvidersFormData>({
    reValidateMode: 'onChange',
    mode: 'all'
  });

  const isEditForm = editProviders !== undefined;

  useEffect(() => {
    editProviders &&
      Object.entries(editProviders).forEach(([key, value]) => {
        // eslint-disable-next-line
        // type Bla = keyof ProvidersFormData;
        // const keyObj = key as Bla;
        // @ts-ignore
        setValue(key, value);
      });
  }, [editProviders, setValue]);

  const handleCreateNewProvider = async (formData: ProvidersFormData) => {
    try {
      // const { data, message, variant } = await createProposals(
      //   formData,
      //   cookies.authToken
      // );
      // dispatchFeedback({
      //   type: FeedbackTypesEnum.SHOW_SNACKBAR,
      //   message,
      //   variant
      // });
      // dispatchProposals({
      //   type: ProposalsTypesEnum.ADD_PROVIDER_TO_STATE,
      //   provider: data
      // });
      // dispatchProposals({
      //   type: ProposalsTypesEnum.OPEN_PROVIDERS_FORM,
      //   form: { open: false }
      // });
    } catch ({ message }) {
      dispatchFeedback({
        type: FeedbackTypesEnum.SHOW_SNACKBAR,
        message,
        variant: VariantEnum.Error
      });
    }
  };

  const handleEditProvider = async (formData: ProvidersFormData) => {
    if (!editProviders?.uid) return;

    try {
      // const { data, message, variant } = await updateProposals(
      //   editProviders?.uid,
      //   formData,
      //   cookies.authToken
      // );
      // dispatchFeedback({
      //   type: FeedbackTypesEnum.SHOW_SNACKBAR,
      //   message,
      //   variant
      // });
      // dispatchProposals({
      //   type: ProposalsTypesEnum.UPDATE_SINGLE_PROVIDER,
      //   provider: data
      // });
      // dispatchProposals({
      //   type: ProposalsTypesEnum.OPEN_PROVIDERS_FORM,
      //   form: { open: false }
      // });
    } catch ({ message }) {
      dispatchFeedback({
        type: FeedbackTypesEnum.SHOW_SNACKBAR,
        message,
        variant: VariantEnum.Error
      });
    }
  };

  const onSubmit = (data: ProvidersFormData) =>
    isEditForm ? handleEditProvider(data) : handleCreateNewProvider(data);

  const handleCreateCustomer = () =>
    dispatchCustomers({
      type: CustomersTypesEnum.OPEN_CUSTOMERS_FORM,
      form: { open: true }
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" alignItems="flex-start">
        <SelectClientField errors={errors} control={control} />
        <Box ml={2} pt={0.5}>
          <IconButton
            onClick={handleCreateCustomer}
            size="medium"
            aria-label="delete"
          >
            <Add />
          </IconButton>
        </Box>
      </Box>

      <MotionContainer open={false}>
        <motion.div variants={varBounceIn}>
          {stateProposals.createProposal.selectedCostumer && (
            <Box mt={5}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>Qual o valor desejado?</Typography>
                <TextField
                  InputProps={{
                    inputComponent: CurrencyInput
                  }}
                  onChange={(event) =>
                    dispatchProposals({
                      type: ProposalsTypesEnum.UPDATE_AMOUNT,
                      amount: event.target.value
                    })
                  }
                  value={stateProposals.createProposal.amount}
                  variant="standard"
                  error={!stateProposals.createProposal.isAmountValid}
                  helperText={
                    stateProposals.createProposal.isAmountValid
                      ? null
                      : 'Min: 1k, Max: 3M'
                  }
                  size="small"
                />
              </Box>

              <SelectAmountField />
            </Box>
          )}
        </motion.div>
      </MotionContainer>

      {/* <Button variant="text" type="submit">
        Salvar
      </Button> */}
    </form>
  );
};
