import { TextField } from '@material-ui/core';
import { GenericControllerTextfieldInterface } from 'src/components/TextfieldController';
import { Autocomplete } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { CustomersContext } from 'src/views/customers/CustomersContext/CustomersContext';
import { ProposalsContext } from '../../ProposalsContext/ProposalsContext';
import { ProposalsTypesEnum } from '../../ProposalsContext/ProposalsInterface';
import { CustomerInterface } from 'src/views/customers/interfaces/CustomerInterface';

interface SelectClientFieldInterface {}

export const SelectClientField: React.FC<
  GenericControllerTextfieldInterface<SelectClientFieldInterface>
> = ({ control, errors }) => {
  const [value, setValue] = useState<CustomerInterface | null>(null);
  const { stateCustomers } = useContext(CustomersContext);
  const { stateProposals, dispatchProposals } = useContext(ProposalsContext);

  const isClientSelected = stateProposals.createProposal.selectedCostumer;

  useEffect(() => {
    if (stateCustomers.lastCreatedCustomer) {
      setValue(stateCustomers.lastCreatedCustomer);
      dispatchProposals({
        type: ProposalsTypesEnum.UPDATE_SELECTED_COSTUMER,
        costumer: stateCustomers.lastCreatedCustomer
      });
    }
  }, [dispatchProposals, stateCustomers.lastCreatedCustomer]);

  return (
    <>
      <Autocomplete
        fullWidth
        noOptionsText="Nenhum cliente criado"
        options={stateCustomers.customers}
        getOptionLabel={(option) => option.fantasy_name}
        value={value}
        onChange={(_, customer: CustomerInterface | null) => {
          setValue(customer);
          dispatchProposals({
            type: ProposalsTypesEnum.UPDATE_SELECTED_COSTUMER,
            costumer: customer
          });
        }}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            margin="none"
            helperText={
              !isClientSelected
                ? 'Você pode cria um novo cliente no botão + ao lado'
                : null
            }
            {...params}
            label="Selecione um cliente"
            placeholder="Quem irá receber a oferta?"
          />
        )}
      />
    </>
  );
};
