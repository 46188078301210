import { ThemeConfig } from './theme';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store, persistor } from './redux/store';

import routes, { renderRoutes } from 'src/routes';

import ScrollToTop from 'src/components/ScrollToTop';

import LoadingScreen from 'src/components/LoadingScreen';

import GoogleAnalytics from 'src/components/GoogleAnalytics';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

import NotistackProvider from 'src/components/NotistackProvider';
import { PersistGate } from 'redux-persist/lib/integration/react';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

// Using for Auth (Check doc https://minimals.cc/docs/authentication)

import JwtProvider from 'src/components/Auth/JwtProvider';
import LayoutProvider from './contexts/LayoutContext/LayoutContext';
import AuthProvider from './contexts/AuthContext/AuthContext';
import FeedbackProvider from './contexts/FeedbackContext/FeedbackContext';
import FirebaseRemoteConfigProvider from './contexts/Firebase/RemoteConfig/FirebaseRemoteConfigProvider';
import firebase from 'firebase/app';
import firebaseConfig from './firebaseConfig';
import { JssProvider, createGenerateId } from 'react-jss';
// import FirebaseProvider from 'src/components/Auth/FirebaseProvider';

// ----------------------------------------------------------------------

firebase.initializeApp(firebaseConfig);

const remoteConfig = firebase.remoteConfig();

const history = createBrowserHistory();

const generateId = createGenerateId();

function App() {
  return (
    <Provider store={store}>
      <JssProvider generateId={generateId} classNamePrefix="Ock-">
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <ThemeConfig>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AuthProvider>
                <FeedbackProvider>
                  <FirebaseRemoteConfigProvider remoteConfig={remoteConfig}>
                    <LayoutProvider>
                      <NotistackProvider>
                        <Router history={history}>
                          <JwtProvider>
                            <ScrollToTop />
                            <GoogleAnalytics />
                            {renderRoutes(routes)}
                          </JwtProvider>
                        </Router>
                      </NotistackProvider>
                    </LayoutProvider>
                  </FirebaseRemoteConfigProvider>
                </FeedbackProvider>
              </AuthProvider>
            </LocalizationProvider>
          </ThemeConfig>
        </PersistGate>
      </JssProvider>
    </Provider>
  );
}

export default App;
