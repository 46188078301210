import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Scrollbars from 'src/components/Scrollbars';
import { convertMoneyToLocale } from 'src/utils/localePriceHelper';
import useProposal from '../hooks/useProposal';
import { ProposalsContext } from '../ProposalsContext/ProposalsContext';
import { ProposalsTypesEnum } from '../ProposalsContext/ProposalsInterface';
import { simulate } from '../services/simulate';

interface ProposalSimulateProps {}

const KEY_PRESS_TIMEOUT = 1000;

const ProposalSimulate: React.FC<ProposalSimulateProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies(['authToken']);
  const { stateProposals, dispatchProposals } = useContext(ProposalsContext);
  const [blockReload, setBlockReload] = useState<boolean>(false);
  const [ruleTimeout, setRuleTimeout] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keypressDelay, setKeypressDelay] = useState<any | undefined>(
    undefined
  );
  const { selectedSimulation } = useProposal();

  const startTimeout = (): void => {
    setRuleTimeout(false);
    clearTimeout(keypressDelay);
    const timeout = setTimeout(() => setRuleTimeout(true), KEY_PRESS_TIMEOUT);
    setKeypressDelay(timeout);
  };

  useEffect(() => {
    if (
      ruleTimeout &&
      !blockReload &&
      setIsLoading &&
      stateProposals?.createProposal?.amount &&
      stateProposals?.createProposal?.isAmountValid
    ) {
      setIsLoading(true);

      dispatchProposals({
        type: ProposalsTypesEnum.UPDATE_SIMULATION_LOADING,
        isLoading: true
      });

      simulate(stateProposals.createProposal.amount, cookies.authToken)
        .then((response) => {
          // setSimulation(response.data);
          dispatchProposals({
            type: ProposalsTypesEnum.UPDATE_SIMULATION,
            simulation: response.data
          });
        })
        .catch((): void => {
          dispatchProposals({
            type: ProposalsTypesEnum.UPDATE_SIMULATION,
            simulation: undefined
          });
          enqueueSnackbar(
            'Ocorreu um problema ao gerar a simulação, tente novamente.',
            { variant: 'error' }
          );
        })
        .finally(() => {
          dispatchProposals({
            type: ProposalsTypesEnum.UPDATE_SIMULATION_LOADING,
            isLoading: false
          });

          setIsLoading(false);
        });
    }

    setBlockReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockReload, ruleTimeout]);

  useEffect(() => {
    startTimeout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // startTimeout,
    stateProposals.createProposal.amount,
    stateProposals.createProposal.isAmountValid
  ]);

  return (
    <Card>
      <CardHeader
        title="Simulação"
        subheader="Aqui você irá criar as opções 
de pagamento para seu cliente"
      />
      <CardContent>
        {isLoading && (
          <Box
            sx={{
              my: 5,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoading &&
          stateProposals.createProposal.simulation &&
          selectedSimulation && (
            <Scrollbars>
              {selectedSimulation.guarantee_type.map((guaranteeType) => (
                <Box mb={4}>
                  <List
                    disablePadding
                    dense={true}
                    subheader={
                      <ListSubheader
                        disableGutters
                        color="primary"
                        component="div"
                        id="nested-list-subheader"
                      >
                        {guaranteeType.label}
                      </ListSubheader>
                    }
                  >
                    {guaranteeType.types.map((type) => {
                      return (
                        <ListItem disableGutters>
                          <ListItemText
                            primary={type.label}
                            secondary={`Até ${
                              type.prazo
                            }x de ${convertMoneyToLocale(
                              type.pmt
                            )} a partir de ${type.taxa}% a.m.`}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              ))}
            </Scrollbars>
          )}
      </CardContent>
    </Card>
  );
};

export default ProposalSimulate;
