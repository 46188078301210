import { layoutInitialState } from '../LayoutInitializer';
import { LayoutContextInterface, LayoutTypesEnum } from '../LayoutInterface';

export type CLOSE_DIALOG = {
  type: LayoutTypesEnum.CLOSE_DIALOG;
};

export function closeDialogAction(
  state: LayoutContextInterface,
  action: CLOSE_DIALOG
): LayoutContextInterface {
  return {
    ...state,
    dialog: layoutInitialState.dialog
  };
}
