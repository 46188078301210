import { OckUserInterface } from './../../../auth/interfaces/UserInterface';
import { UsersContextInterface, UsersTypesEnum } from '../UsersInterface';

export type UPDATE_CUSTOMERS_LIST = {
  type: UsersTypesEnum.UPDATE_CUSTOMERS_LIST;
  users: OckUserInterface[];
};

export function updateUsersListAction(
  state: UsersContextInterface,
  action: UPDATE_CUSTOMERS_LIST
): UsersContextInterface {
  return {
    ...state,
    users: action.users
  };
}
