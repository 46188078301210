import React, { createContext, useEffect, useReducer } from 'react';
import Reducer from './CustomersReducer';
import { customersInitialState } from './CustomersInitializer';
import {
  CustomersContextInterface,
  CustomersAction,
  CustomersTypesEnum
} from './CustomersInterface';
import useSWR from 'swr';
import { listCustomers } from '../services/listCustomers';
import { useCookies } from 'react-cookie';

export type CustomersDispatchType = React.Dispatch<CustomersAction>;

interface CustomersCustomerInterface {
  stateCustomers: CustomersContextInterface;
  dispatchCustomers: CustomersDispatchType;
}

export const CustomersContext = createContext<CustomersCustomerInterface>({
  stateCustomers: customersInitialState,
  dispatchCustomers: () => console.warn('CustomersDispatch not ready')
});

const CustomersCustomer: React.FC = ({ children }) => {
  const [cookies] = useCookies(['authToken']);
  const [stateCustomers, dispatchCustomers] = useReducer(
    Reducer,
    customersInitialState
  );

  const customersRequest = () => listCustomers(cookies.authToken);
  const { data } = useSWR(
    `/customers/${cookies.authToken}`,
    customersRequest,
    {}
  );

  useEffect(() => {
    const customers = data?.data;
    customers &&
      dispatchCustomers({
        type: CustomersTypesEnum.UPDATE_CUSTOMERS_LIST,
        customers
      });
  }, [data, dispatchCustomers]);

  const globals = {
    stateCustomers,
    dispatchCustomers
  };

  return (
    <CustomersContext.Provider value={globals}>
      {children}
    </CustomersContext.Provider>
  );
};

export default CustomersCustomer;
