// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Helmet } from 'react-helmet';

import track from 'src/utils/analytics';
import { useLocation } from 'react-router-dom';
import React, { forwardRef, useEffect, useCallback } from 'react';

// ----------------------------------------------------------------------

interface PageInterface {
  title: string;
  className?: string;
  other?: any;
}

const Page: React.FC<PageInterface> = forwardRef(
  ({ children, title = '', ...other }, ref) => {
    const { pathname } = useLocation();

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: pathname
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      sendPageViewEvent();
    }, [sendPageViewEvent]);

    return (
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      <div ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {children}
      </div>
    );
  }
);

export default Page;
