import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

import objFromArray from 'src/utils/objFromArray';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  mails: { byId: {}, allIds: [] },
  labels: [],
  isOpenSidebar: false,
  isOpenCompose: false
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload;

      state.isLoading = false;
      state.mails.byId = objFromArray(mails);
      // @ts-ignore ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message
      // @ts-ignore ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'never[]... Remove this comment to see the full error message

      state.mails.allIds = Object.keys(state.mails.byId);
    },

    // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    // GET MAIL
    getMailSuccess(state, action) {
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      const mail = action.payload;
      // @ts-ignore
      state.mails.byId[mail.id] = mail;
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      if (!state.mails.allIds.includes(mail.id)) {
        // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
        state.mails.allIds.push(mail.id);
      }
    },

    // SIDEBAR
    openSidebar(state) {
      state.isOpenSidebar = true;
    },

    closeSidebar(state) {
      state.isOpenSidebar = false;
    },

    // COMPOSE
    openCompose(state) {
      state.isOpenCompose = true;
    },

    closeCompose(state) {
      state.isOpenCompose = false;
    }
  }
});

// Reducer
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
export default slice.reducer;

// Actions
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
export const {
  openSidebar,
  closeSidebar,
  openCompose,
  closeCompose
} = slice.actions;

// ----------------------------------------------------------------------

export function getLabels() {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/labels');
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'mailId' implicitly has an 'any' type.
      dispatch(slice.actions.getLabelsSuccess(response.data.labels));
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMails(params: {}) {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/mails', { params });
      dispatch(slice.actions.getMailsSuccess(response.data.mails));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMail(mailId: any) {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/mail/mail', {
        params: { mailId }
      });
      dispatch(slice.actions.getMailSuccess(response.data.mail));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
