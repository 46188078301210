import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'isLoading' does not exist on type 'Writa... Remove this comment to see the full error message
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'isLoading' does not exist on type 'Writa... Remove this comment to see the full error message
      state.isLoading = false;
      // @ts-ignore ts-migrate(2339) FIXME: Property 'error' does not exist on type 'WritableD... Remove this comment to see the full error message
      state.error = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      const notifications = action.payload;
      state.notifications = notifications;
    },

    // MARK ALL AS READ
    markAllAsRead(state) {
      const markAll = state.notifications.map((notification) => {
        return {
          // @ts-ignore ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...notification,
          isUnRead: false
        };
      });

      // @ts-ignore ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      state.notifications = markAll;
    }
  }
});

// @ts-ignore ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
// Reducer
export default slice.reducer;

// Actions
// @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
export const { markAllAsRead } = slice.actions;

// @ts-ignore ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/notifications');
      dispatch(
        // @ts-ignore ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
