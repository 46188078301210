// @ts-ignore
import NProgress from 'nprogress';

import AppRoutes from './AppRoutes';
import { PATH_PAGE } from './paths';

import LoadingScreen from 'src/components/LoadingScreen';

import GuestProtect from 'src/components/Auth/GuestProtect';

import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense, Fragment, lazy, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AdminRoutes from './AdminRoutes';
// import { OmitNative } from 'react-router';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        // @ts-ignore ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props: any) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  // @ts-ignore ts-migrate(2739) FIXME: Type 'never[]' is missing the following properties... Remove this comment to see the full error message
  useEffect(() => {
    NProgress.done();
    return () => {
      // @ts-ignore ts-migrate(2339) FIXME: Property 'map' does not exist on type 'Routes'.
      // @ts-ignore ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
      NProgress.start();
    };
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(routes: Routes[] = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Component = route.component;
          const Guard = (route?.guard && route?.guard[0]) || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              // @ts-ignore
              render={(props) => (
                <Guard>
                  {/* <Guard1> */}
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      // @ts-ignore
                      <Component {...props} />
                    )}
                  </Layout>
                  {/* </Guard1> */}
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

export interface Routes {
  routes?: Routes[];
  exact?: boolean;
  guard?: (JSX.Element | Element | any)[];
  path?: string | string[];
  layout?: any;
  component?:
    | React.LazyExoticComponent<() => JSX.Element | null>
    | (() => JSX.Element)
    | React.FC;
}

const routes: Routes[] = [
  // Others Routes
  {
    exact: true,
    guard: [GuestProtect],
    path: PATH_PAGE.auth.login,

    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.loginUnprotected,

    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: [GuestProtect],
    path: PATH_PAGE.auth.register,
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.resetPassword,
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  {
    exact: true,

    path: PATH_PAGE.auth.verify,
    component: lazy(() => import('src/views/auth/VerifyCodeView'))
  },
  {
    exact: true,
    path: '/404',

    component: lazy(() => import('src/views/errors/Page404View'))
  },
  {
    exact: true,
    path: '/500',

    component: lazy(() => import('src/views/errors/Page500View'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.root,
    component: () => <Redirect to={PATH_PAGE.auth.login} />
  },

  {
    exact: true,
    // guard: [AuthProtect],
    path: '',
    routes: [AdminRoutes, AppRoutes]
  }
];

export default routes;
