import React, { createContext, useReducer } from 'react';
import Reducer, { LayoutAction } from './LayoutReducer';
import { layoutInitialState } from './LayoutInitializer';
import { LayoutContextInterface } from './LayoutInterface';

export type LayoutDispatchType = React.Dispatch<LayoutAction>;

interface LayoutProviderInterface {
  stateLayout: LayoutContextInterface;
  dispatchLayout: LayoutDispatchType;
}

export const LayoutContext = createContext<LayoutProviderInterface>({
  stateLayout: layoutInitialState,
  dispatchLayout: () => console.warn('LayoutDispatch not ready')
});

const LayoutProvider: React.FC = ({ children }) => {
  const [stateLayout, dispatchLayout] = useReducer(Reducer, layoutInitialState);

  const globals = {
    stateLayout,
    dispatchLayout
  };

  return (
    <LayoutContext.Provider value={globals}>{children}</LayoutContext.Provider>
  );
};

export default LayoutProvider;
