import clsx from 'clsx';
import React from 'react';

import { varFadeInUp } from 'src/components/Animate';
import { motion, AnimatePresence } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper
  }
}));

type Props = {
  open: boolean;
  onClose?: (...args: unknown[]) => unknown;
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  maxWidth?: string;
  scroll?: string;
  other?: any;
};

function DialogAnimate({
  open = false,
  onClose,
  children,
  className,
  fullWidth,
  maxWidth,
  ...other
}: Props) {
  const classes = useStyles();

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          // @ts-ignore
          // @ts-ignore ts-migrate(2559) FIXME: Type '{ initial: { y: number; opacity: number; }; ... Remove this comment to see the full error message
          PaperComponent={motion.div}
          // @ts-ignore
          PaperProps={{ ...varFadeInUp }}
          classes={{ paper: classes.paper }}
          className={clsx(classes.root, className)}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}

export default DialogAnimate;
