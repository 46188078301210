import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Box } from '@material-ui/core';

type Props = {
  children?: React.ReactNode;
  className?: string;
  variants?: object;
  transition?: object;
  triggerOnce?: boolean;
  threshold?: number | number[] | undefined;
};

function MotionInView({
  children,
  className,
  variants,
  transition,
  threshold,
  ...other
}: Props) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: threshold ? threshold : 0,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView && variants) {
      controls.start(Object.keys(variants)[1]);
    } else {
      variants && controls.start(Object.keys(variants)[0]);
    }
  }, [controls, inView, variants]);

  return (
    // @ts-ignore
    <Box
      ref={ref}
      component={motion.div}
      initial={variants && Object.keys(variants)[0]}
      animate={controls}
      variants={variants}
      transition={transition}
      className={className}
      {...other}
    >
      {children}
    </Box>
  );
}

export default MotionInView;
